import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants'
import { logout } from './authSlice'

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'include',  // Allow cookies to be sent with the request
    
})

async function baseQueryWithAuth(args, api, extra) {
    const result = await baseQuery(args, api, extra)

    // Handle 401 errors and log out the user
    if (result.error && result.error.status === 401) {
        api.dispatch(logout())  // Dispatch logout action if unauthorized
    }
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithAuth,  // Use the custom baseQuery with authentication
    tagTypes: ['Products', 'Order', 'User', 'Banner', 'Cart', 'Category', 'Promotional', 'Topselling', 'Trending', 'Featured'],
    endpoints: (builder) => ({
        // Define your endpoints here
    }),
})
