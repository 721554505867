import React from 'react'
import Slider from 'react-slick'
import Loader from '../components/Loader'
import { useGetBannerQuery } from '../slices/bannerApiSlice'

const BannerTwo = () => {
    const { data: banners, error, isLoading } = useGetBannerQuery()

    const backendUrl = process.env.REACT_APP_BACKEND_URL

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    }

    return isLoading ? (
        <Loader />
    ) : error ? (
        <p></p>
    ) : (
        <div className="banner-two">
            <div className="container container-lg">
                <div className="banner-two-wrapper d-flex align-items-start">
                    <div className="banner-item-two-wrapper overflow-hidden position-relative arrow-center flex-grow-1 mb-0">
                        <div className="banner-item-two__slider">
                            <Slider {...settings}>
                                {banners && banners.map((banner) => (
                                    <React.Fragment key={banner._id}>
                                        {/* Image 1 */}
                                        <div className="banner-item-two">
                                            <img
                                                src={`${backendUrl}${banner.images?.image1?.imageUrl}` || { banner }}
                                                alt="Hawaseeb"
                                                className="banner-img inset-block-start-0 inset-inline-start-0 w-100 h-100 z-n1 object-fit-cover"
                                            />
                                        </div>
                                    </React.Fragment>
                                ))}
                                {banners && banners.map((banner) => (
                                    <React.Fragment key={banner._id}>
                                        <div className="banner-item-two">
                                            <img
                                                src={`${backendUrl}${banner.images?.image2?.imageUrl}` || { banner }}
                                                alt="Hawaseeb"
                                                className="banner-img  inset-block-start-0 inset-inline-start-0 w-100 h-100 z-n1 object-fit-cover"
                                            />
                                        </div>
                                    </React.Fragment>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerTwo
