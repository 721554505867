import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { getCountdown } from '../helper/Countdown'
import banner from '../assets/banner/banner.jpg'
import { useGetDealOfTheDayQuery } from '../slices/bannerApiSlice'


const SampleNextArrow = memo(function SampleNextArrow(props) {



    const { className, onClick } = props
    return (
        <button
            type="button" onClick={onClick}
            className={` ${className} slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-neutral-600 text-xl hover-bg-neutral-600 hover-text-white transition-1`}
        >
            <i className="ph ph-caret-right" />
        </button>
    )
})

const SamplePrevArrow = memo(function SamplePrevArrow(props) {
    const { className, onClick } = props
    return (
        <button
            type="button"
            onClick={onClick}
            className={`${className} slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-neutral-600 text-xl hover-bg-neutral-600 hover-text-white transition-1`}
        >
            <i className="ph ph-caret-left" />
        </button>
    )
})

const DealsOne = () => {
    const [timeLeft, setTimeLeft] = useState(getCountdown())
    const { data: dealoftheday, error, isLoading } = useGetDealOfTheDayQuery()


    const backendUrl = process.env.REACT_APP_BACKEND_URL

    useEffect(() => {
        if (!isLoading && dealoftheday && dealoftheday.length > 0 && dealoftheday[0]?.expireDate) {
            const targetDate = new Date(dealoftheday[0].expireDate)


            const interval = setInterval(() => {
                setTimeLeft(getCountdown(targetDate))
            }, 1000)

            return () => clearInterval(interval)
        }
    }, [dealoftheday, isLoading])


    if (isLoading) {
        return <div>Loading...</div>
    }


    if (error || !dealoftheday || dealoftheday.length === 0) {
        return <div></div>
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 5,

                },
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,

                },
            },

        ],
    }
    return (
        <section className="deals-weeek pt-24">
            <div className="container container-lg">
                <div className="border border-gray-100 p-24 rounded-16">
                    <div className="section-heading mb-24">
                        <div className="flex-between flex-wrap gap-8">
                            <h5 className="mb-0">{dealoftheday[0].title || 'Deal of the Day'}</h5>
                            <div className="flex-align mr-point gap-16">
                                <Link
                                    to="/shop"
                                    className="text-sm fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
                                >
                                    View All Deals
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="deal-week-box rounded-16 overflow-hidden flex-between position-relative z-1 mb-24">
                        <img
                            src="assets/images/bg/week-deal-bg.png"
                            alt=""
                            className="position-absolute inset-block-start-0 inset-block-start-0 w-100 h-100 z-n1 object-fit-cover"
                        />
                        <div className="d-lg-block d-none ps-32 flex-shrink-0">
                            <img src={dealoftheday[0].bannerLeftImage} alt="" width='195px' height="195px" />
                        </div>
                        <div className="deal-week-box__content px-sm-4 d-block w-100 text-center">
                            <h6 className="mb-20">{dealoftheday[0].bannerText || 'Amazing Deals'}</h6>
                            <div className="countdown mt-20" id="countdown4">
                                <ul className="countdown-list style-four flex-center flex-wrap">
                                    <li className="countdown-list__item flex-align flex-column text-sm fw-medium text-white rounded-circle bg-neutral-600">
                                        <span className="days" />
                                        {timeLeft.days} <br /> Days
                                    </li>
                                    <li className="countdown-list__item flex-align flex-column text-sm fw-medium text-white rounded-circle bg-neutral-600">
                                        <span className="hours" />
                                        {timeLeft.hours} <br /> Hour
                                    </li>
                                    <li className="countdown-list__item flex-align flex-column text-sm fw-medium text-white rounded-circle bg-neutral-600">
                                        <span className="minutes" />
                                        {timeLeft.minutes} <br /> Min
                                    </li>
                                    <li className="countdown-list__item flex-align flex-column text-sm fw-medium text-white rounded-circle bg-neutral-600">
                                        <span className="seconds" />
                                        {timeLeft.seconds} <br /> Sec
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="d-lg-block d-none flex-shrink-0 pe-xl-5">
                            <div className="me-xxl-5">
                                <img src={`${backendUrl}${dealoftheday[0].bannerRightImage}` || { banner }} alt="" width='301px' height="227px" />
                            </div>
                        </div>
                    </div>


                    <div className="deals-week-slider arrow-style-two ">
                        {dealoftheday && dealoftheday.length > 0 ? (
                            dealoftheday.map((deal) => (
                                <Slider {...settings}>


                                    {/* Featured Products */}
                                    {deal.featuredProducts?.map((product) => (
                                        <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                            <Link
                                                to="/product-details-two"
                                                className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                            >
                                                <span className="product-card__badge bg-main-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                                    Sold
                                                </span>
                                                <img
                                                    src={`${backendUrl}${product.images.image1}`}
                                                    alt={product.productName}
                                                    className=" max-w-unset"
                                                />
                                            </Link>
                                            <div className="product-card__content mt-16">
                                                <div className="flex-align gap-6">
                                                    <span className="text-xs fw-medium text-gray-500">4.8</span>
                                                    <span className="text-15 fw-medium text-warning-600 d-flex">
                                                        <i className="ph-fill ph-star" />
                                                    </span>
                                                    <span className="text-xs fw-medium text-gray-500">(17k)</span>
                                                </div>
                                                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                                    <Link
                                                        to="/product-details-two"
                                                        className="link text-line-2"
                                                        tabIndex={0}
                                                    >
                                                        {product.productName}
                                                    </Link>
                                                </h6>
                                                <div className="flex-align gap-4">
                                                    <span className="text-tertiary-600 text-md d-flex">
                                                        <i className="ph-fill ph-storefront" />
                                                    </span>
                                                    <span className="text-gray-500 text-xs">
                                                        By {product.brand.brand}
                                                    </span>
                                                </div>
                                                <div className="mt-8">
                                                    <div
                                                        className="progress w-100 bg-color-three rounded-pill h-4"
                                                        role="progressbar"
                                                        aria-label="Basic example"
                                                        aria-valuenow={100}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                    >
                                                        <div
                                                            className="progress-bar bg-tertiary-600 rounded-pill"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="product-card__price my-20">
                                                    <span className="text-heading text-md fw-semibold pe-2">
                                                        AED {product.finalPrice} {" "}
                                                    </span>
                                                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                        AED {product.finalPrice} {" "}
                                                    </span>
                                                </div>
                                                <Link
                                                    to="/cart"
                                                    className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                                    tabIndex={0}
                                                >
                                                    Add To Cart <i className="ph ph-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            ))
                        ) : (
                            <p></p>
                        )}
                    </div>


                </div>
            </div>


        </section>

    )
}

export default DealsOne