import React from 'react'
import Address from './Address'
import PersonalDetails from './PersonalDetails'

const Profile = () => {
  return (
    <div>
      Profile
      <PersonalDetails />
      <Address/>
    </div>
  )
}

export default Profile
