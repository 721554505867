import React from 'react'
import { useLoginWithGoogleMutation } from '../slices/userApiSlice'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import { setCredentials } from '../slices/authSlice'

const GoogleAuth = () => {

    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

    const dispatch = useDispatch()

    const [loginWithGoogle] = useLoginWithGoogleMutation()

    const handleSuccess = async (credentialResponse) => {
        try {
            const { data } = await loginWithGoogle({
                token: credentialResponse.credential,
            })

            dispatch(setCredentials(data))
        } catch (error) {
            console.error('Error:', error.response?.data || error.message)
        }
    }


    const handleError = () => {
        console.error('Google Login Failed')
    }

    return (
        <div>
            <GoogleOAuthProvider clientId={googleClientId}>
                <GoogleLogin
                    onSuccess={handleSuccess}
                    onError={handleError}
                />
            </GoogleOAuthProvider>
        </div>
    )
}

export default GoogleAuth
