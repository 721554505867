import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetAllCategoryQuery } from '../slices/categoryApiSlice'
import { useGetAllSubcategoryQuery } from '../slices/subcategoryApiSlice'

const HeaderTwo = () => {
    const [scroll, setScroll] = useState(false)
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false)  // State to control the visibility of the dropdown

    const { data: categories } = useGetAllCategoryQuery()
    const { data: subcategories } = useGetAllSubcategoryQuery()

    const [activeCategory, setActiveCategory] = useState(null)

    // Group subcategories by categoryId
    const groupedCategories = categories?.map((category) => {
        const relatedSubcategories = subcategories?.filter(
            (subcategory) => subcategory.categoryId._id === category._id
        )
        return { ...category, subcategories: relatedSubcategories }
    })

    const handleCategoryToggle = (categoryId) => {
        setActiveCategory(activeCategory === categoryId ? null : categoryId)
    }

    const handleMouseEnter = (categoryId) => {
        setActiveCategory(categoryId)
    }

    const handleMouseLeave = () => {
        setActiveCategory(null)
    }

    const toggleCategoryDropdown = () => {
        setShowCategoryDropdown(!showCategoryDropdown)  // Toggle the dropdown visibility
    }

    useEffect(() => {
        window.onscroll = () => {
            if (window.pageYOffset < 150) {
                setScroll(false)
            } else if (window.pageYOffset > 150) {
                setScroll(true)
            }
            return () => (window.onscroll = null)
        }
    }, [])

    return (
        <>
            {/* ======================= Middle Header Two Start ========================= */}
            <header className="header-middle style-two bg-color-neutral">
                <div className="container container-lg">
                    <nav className="header-inner d-flex align-items-center">
                        {/* Logo Start */}
                        <Link to="/" className="link d-flex">
                            <div className="logo d-flex flex-column align-items-center justify-content-center">
                                <div className='me-3'>
                                    <img src="../assets/images/logo/hawaseeb.png" alt="Hawaseeb" />
                                </div>
                                <div>
                                    <h6 className='text-light fw-bold m-0 logo-text'>HAWASEEB</h6>
                                </div>
                            </div>
                        </Link>
                        {/* Logo End */}

                        <div className="position-relative d-flex align-items-center w-100 justify-content-center">
                            {/* Search Field */}
                            <div className="search-category style-two d-flex h-40 search-form d-sm-flex">
                                <button
                                    type="button"
                                    onClick={toggleCategoryDropdown}
                                    className="bg-main-two-600 flex-center text-xl text-white flex-shrink-0 w-48 hover-bg-main-two-700"
                                >
                                    <i className="ph ph-dots-nine text-2xl text-light" />
                                </button>

                                <div className="search-form__wrapper position-relative">
                                    <input
                                        type="text"
                                        className="search-form__input common-input py-10 ps-16 pe-18 rounded-0 border-0"
                                        placeholder="Search for a product or brand"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="bg-main-two-600 flex-center text-xl text-white flex-shrink-0 w-48 hover-bg-main-two-700"
                                >
                                    <i className="ph ph-magnifying-glass" />
                                </button>
                            </div>

                            {/* Dropdown */}
                            {showCategoryDropdown && (
                                <div className="category-dropdown">
                                    <ul className="p-0 m-0">
                                        {groupedCategories?.map((category) => (
                                            <li
                                                key={category._id}
                                                className={`category-item ${activeCategory === category._id ? 'active' : ''}`}
                                                onMouseEnter={() => handleMouseEnter(category._id)}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <Link
                                                    to="#"
                                                    className="text-gray-500 text-15 py-12 px-16 flex-align gap-8 rounded-0"
                                                >
                                                    <span className="text-xl d-flex">
                                                        <i className="ph ph-cube" />
                                                    </span>
                                                    <span>{category.category}</span>
                                                    <span className="icon text-md d-flex ms-auto">
                                                        <i className="ph ph-caret-right" />
                                                    </span>
                                                </Link>

                                                {category.subcategories && (
                                                    <div className="subcategories-list py-16">
                                                        <h6 className="text-lg px-16 subcategories-list__title">
                                                            {category.category}
                                                        </h6>
                                                        <ul className="subcategories-list__list max-h-300 overflow-y-auto scroll-sm">
                                                            {category.subcategories.map((subcategory) => (
                                                                <li key={subcategory._id}>
                                                                    <Link to="/shop">{subcategory.subcategory}</Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                    </nav>
                </div>
            </header>
            {/* ======================= Middle Header Two End ========================= */}
        </>
    )
}

export default HeaderTwo
