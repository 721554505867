import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faHouse, faBagShopping, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetCartQuery } from '../slices/cartApiSlice'
import { Nav } from 'react-bootstrap'
import { FaShoppingCart } from 'react-icons/fa'

const SubHeader = ({ onSidebarToggle }) => {
    const [scroll, setScroll] = useState(false)

    // Navigation links with icons
    const navLinks = [
        { path: '/', icon: faHouse, label: 'Home' },
        { path: '/shop', icon: faBagShopping, label: 'Shop' },
    ]

    const { cartItems } = useSelector((state) => state.cart)
    const { userDetails } = useSelector((state) => state.auth)

    // Calculate total cart items safely
    const totalCartItems = Array.isArray(cartItems)
        ? cartItems.reduce((total, item) => total + item.qty, 0)
        : 0

    // Handle scroll behavior for fixed header
    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.pageYOffset > 150)
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <div className="subheader-fixed">
            {/* ==================== Header Start ==================== */}
            <header
                className={`header bg-white border-bottom border-gray-100 w-100 ${scroll ? 'fixed-header' : ''}`}
            >
                <div className="">
                    <nav className="">
                        {/* Menu Start */}
                        <div className="header-menu">
                            <ul className="nav-menu d-flex flex-align gap-4">
                                {navLinks.map((link, index) => (
                                    <li className="nav-menu__item" key={index}>
                                        <NavLink
                                            to={link.path}
                                            className={(navData) =>
                                                navData.isActive
                                                    ? 'nav-menu__link activePage'
                                                    : 'nav-menu__link'
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={link.icon}
                                                size="lg"
                                                className="icon-style"
                                            />
                                        </NavLink>
                                    </li>
                                ))}

                                {/* Cart Icon and Count */}
                                <li>
                                    <NavLink
                                        to="/cart"
                                        className={(navData) =>
                                            navData.isActive
                                                ? 'nav-menu__link activePage'
                                                : 'nav-menu__link'
                                        }
                                    >
                                        <span className="text-2xl d-flex position-relative me-6 mt-6 item-hover__text">
                                            <FontAwesomeIcon icon={faShoppingCart} size="lg" className="icon-style" />
                                            <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-xs position-absolute top-n6 end-n4">
                                                {totalCartItems > 0 && totalCartItems}
                                            </span>
                                        </span>
                                    </NavLink>
                                </li>





                                {/* User Icon */}
                                <li>
                                    <button
                                        onClick={onSidebarToggle}
                                        className={`nav-menu__link ${onSidebarToggle ? 'activePage' : ''}`}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'black',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faUser} size="lg" className="icon-style" />
                                    </button>
                                </li>
                            </ul>
                        </div>
                        {/* Menu End */}
                    </nav>
                </div>
            </header>
            {/* ==================== Header End ==================== */}

            {/* Additional Inline CSS for Spacing and Icon Styling */}
            <style>
                {`
                .nav-menu {
                    display: flex;
                    justify-content: space-around; /* Space around for desktop */
                    align-items: center;
                    width: 100%;
                }

                .nav-menu__link {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    color: black;
                }

                .icon-style {
                    font-size: 1.2rem; /* Default size */
                }

                @media (max-width: 768px) {
                    .nav-menu {
                        justify-content: space-around; /* Space between for mobile */
                    }
                    .icon-style {
                        font-size: 2rem; /* Larger icons on smaller screens */
                    }
                }

                @media (max-width: 480px) {
                    .icon-style {
                        font-size: 1.2rem; /* Even larger icons on very small screens */
                    }
                }
                `}
            </style>
        </div>
    )
}

export default SubHeader
