import React, { useState } from "react"
import Preloader from "../helper/Preloader"
import HeaderTwo from "../components/HeaderTwo"
import BannerTwo from "../components/BannerTwo"
import DealsOne from "../components/DealsOne"
import TopSellingOne from "../components/TopSellingOne"
import TrendingOne from "../components/TrendingOne"
import BrandTwo from "../components/BrandTwo"
import ShippingTwo from "../components/ShippingTwo"
import FooterTwo from "../components/FooterTwo"
import BottomFooter from "../components/BottomFooter"
import SubHeader from "../components/SubHeader"
import Sidebar from "../components/Sidebar"
import ScrollToTop from "react-scroll-to-top"
import ColorInit from "../helper/ColorInit"

const HomePageTwo = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)

  return (
    <>
      {/* ColorInit */}
      <ColorInit color={true} />

      {/* Preloader */}
      <Preloader />

      {/* HeaderTwo */}
      <HeaderTwo category={false} />

      {/* SubHeader */}
      <SubHeader onSidebarToggle={toggleSidebar} />

      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />

      {/* BannerTwo */}
      <BannerTwo />

      {/* Deals */}
      <DealsOne />

      {/* TopSellingOne */}
      <TopSellingOne />

      {/* Trending */}
      <TrendingOne />

      {/* Brand Section */}
      <BrandTwo />

      {/* Shipping */}
      <ShippingTwo />

      {/* Footer */}
      <FooterTwo />
      
      <BottomFooter />
    </>
  )
}

export default HomePageTwo
