import { AUTH_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (data) => ({
                url: `${AUTH_URL}/user/login`,
                method: "POST",
                body: data,
                credentials: 'include',
            })
        }),
        registerUser: builder.mutation({
            query: (data) => ({
                url: `${AUTH_URL}/user/register`,
                method: "POST",
                body: data,
                credentials: 'include',
            })
        }),
        forgotPassword: builder.mutation({
            query: (data) => ({
                url: `${AUTH_URL}/user/forgot`,
                method: "POST",
                body: data,
                credentials: 'include',
            })
        }),
        verifyOtp: builder.mutation({
            query: (data) => ({
                url: `${AUTH_URL}/user/verify`,
                method: "POST",
                body: data,
                credentials: 'include',
            })
        }),
        logout: builder.mutation({
            query: (data) => ({
                url: `${AUTH_URL}/user/logout`,
                method: "POST",
                body: data,
                credentials: 'include',
            })
        }),
        loginWithGoogle: builder.mutation({
            query: (tokenPayload) => ({
                url: `${AUTH_URL}/google`,
                method: "POST",
                body: tokenPayload,
            }),
        }),

    })
})

export const { useLoginUserMutation, useRegisterUserMutation, useForgotPasswordMutation, useVerifyOtpMutation, useLogoutMutation, useLoginWithGoogleMutation } = userApiSlice