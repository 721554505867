import { ADDRESS_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const addressApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addAddress: builder.mutation({
            query: (data) => ({
                url: ADDRESS_URL,
                method: 'POST',
                body: data,
            }),
        }),
        getAddress: builder.query({
            query: () => ({
                url: ADDRESS_URL
            }),
            providesTags: ['Address'],
            keepUnusedDataFor: 5,
        }),
    })
})

export const {
    useAddAddressMutation, useGetAddressQuery
} = addressApiSlice