import React, { useState } from "react"
import Preloader from "../helper/Preloader"
import HeaderTwo from "../components/HeaderTwo"
import Breadcrumb from "../components/Breadcrumb"
import ShopSection from "../components/ShopSection"
import ShippingTwo from "../components/ShippingTwo"
import FooterTwo from "../components/FooterTwo"
import ColorInit from "../helper/ColorInit"
import ScrollToTop from "react-scroll-to-top"
import ShippingAddress from "../components/ShippingAddress"
import CheckoutSteps from "../components/CheckOutSteps"
import SubHeader from "../components/SubHeader"
import Sidebar from "../components/Sidebar"

const ShippingAddressPage = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)

    return (
        <>
            {/* ColorInit */}
            <ColorInit color={true} />

            {/* ScrollToTop */}
            {/* <ScrollToTop smooth color="#FA6400" /> */}

            {/* Preloader */}
            {/* <Preloader /> */}

            {/* HeaderOne */}
            <HeaderTwo category={true} />

            {/* SubHeader */}
            <SubHeader onSidebarToggle={toggleSidebar} />

            {/* Sidebar */}
            <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />

            <CheckoutSteps step1 step2 />

            {/* ShopSection */}
            <ShippingAddress />

            {/* ShippingTwo */}
            <ShippingTwo />

            {/* FooterTwo */}
            <FooterTwo />


        </>
    )
}

export default ShippingAddressPage
