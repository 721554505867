import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForgotPasswordMutation } from '../slices/userApiSlice'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
    const [loginId, setLoginId] = useState('')
    const [forgotPassword] = useForgotPasswordMutation()
    const navigate = useNavigate()

    const { search } = useLocation()
    const sp = new URLSearchParams(search)
    const redirect = sp.get('redirect') || '/'

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!loginId) {
            toast.error("Please enter your email or phone.")
            return
        }

        try {
            // Call the forgot password mutation
            const res = await forgotPassword({ loginId }).unwrap()

            // Save loginId to localStorage to pass it to the /verify page
            localStorage.setItem('loginId', loginId)
            localStorage.setItem('otpTimer', 60)

            // Navigate to the verify page
            navigate('/verify', { replace: true })

            console.log("Forgot password request was successful: ", res.message)
        } catch (err) {
            console.error("Error in forgot password request:", err)
            toast.error(err?.data?.message || "Something went wrong. Please try again.")
        }
    }

    return (
        <div>
            <section className="account pb-40 pt-22">
                <div className="container container-lg">
                    <form onSubmit={handleSubmit}>
                        <div className="row gy-4 d-flex justify-content-center">
                            {/* Left Card for Login or Registration */}
                            <div className="col-xl-6">
                                <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                                    <>
                                        <h6 className="text-xl mb-32">Forgot Password</h6>
                                        <div className="mb-24">
                                            <label htmlFor="loginId" className="text-neutral-900 text-lg mb-8 fw-medium">
                                                Enter Email address or Phone<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="common-input"
                                                id="loginId"
                                                placeholder="Email or Phone"
                                                value={loginId}
                                                onChange={(e) => setLoginId(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-15">
                                            <button type="submit" className="btn btn-main py-18 px-40 me-30">
                                                Submit
                                            </button>
                                            <span>Back to {' '}
                                                <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                                                    Login
                                                </Link>
                                            </span>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default ForgotPassword
