// export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''

export const BASE_URL = 'http://localhost:5000'
export const AUTH_URL = '/api/auth'
export const PRODUCTS_URL = '/api/product'
export const CART_URL = '/api/cart'
export const CHECKOUT_URL = '/api/checkout'
export const PINCODE_URL = '/api/pincode'
export const ADDRESS_URL = '/api/address'
export const CATEGORY_URL = '/api/category'
export const SUBCATEGORY_URL = '/api/subcategory'
export const BRAND_URL = '/api/brand'
export const BANNER_URL = '/api/banner'
export const DEAL_URL = '/api/dealoftheday'
export const PROMOTIONAL_URL = '/api/promotional'
export const TOPSELLING_URL = '/api/topselling'
export const TRENDING_URL = '/api/trending'
export const FEATURED_URL = '/api/featured'
export const RECOMMENDED_URL = '/api/recommended'
export const POPULAR_PRODUCTS_URL = '/api/popularproduct'
export const ORDER_URL = '/api/order'