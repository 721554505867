import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import QuantityControl from '../helper/QuantityControl'
import { addToCart as addToCartLocal, removeFromCart as removeFromCartLocal } from '../slices/cartSlice'
import { BASE_URL } from '../constants'
import { Button } from 'react-bootstrap'
import { useGetCartQuery, useAddCartMutation, useRemoveFromCartMutation, useUpdateOrAddCartItemMutation } from '../slices/cartApiSlice'
import { FaTrashAlt } from 'react-icons/fa'

const CartSection = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Fetch cart data
    const { data: cartData, error, isLoading, refetch } = useGetCartQuery()
    const [addCart] = useAddCartMutation()
    const [removeFromCart] = useRemoveFromCartMutation()
    const [updateOrAddCart] = useUpdateOrAddCartItemMutation()

    // Get user details and local cart
    const { userDetails } = useSelector((state) => state.auth)
    const cart = useSelector((state) => state.cart)
    const baseUrl = BASE_URL

    // Map cart items based on user login status
    const cartItems = userDetails
        ? cartData?.cartItems?.map((item) => ({
            ...item?.product,
            qty: item?.qty || 0,
        })) || []
        : cart.cartItems

    // Calculate total price with fallback
    const totalPrice = cartItems.reduce((acc, item) => {
        const price = item?.finalPrice || 0
        return acc + item.qty * price
    }, 0)

    // Add to cart handler
    // Correct quantity handling (set directly)
    // Add to Cart Handler (Update or Add Cart Item)
    const addToCartHandler = async (item, qty) => {
        console.log('Updating or Adding to cart:', item.productName, 'Qty:', qty)

        if (userDetails) {
            try {
                // Use the updateOrAddCart mutation
                await updateOrAddCart({ productId: item._id, qty }).unwrap()
                refetch() // Refetch cart data to update the UI
            } catch (error) {
                console.error('Error updating cart:', error)
            }
        } else {
            // Update local cart for guest users
            dispatch(addToCartLocal({ ...item, qty }))
        }
    };

    



    // Remove from cart handler
    const removeFromCartHandler = (id) => {
        if (userDetails) {
            removeFromCart(id)
            console.log(id)
        } else {
            dispatch(removeFromCartLocal(id))
        }
    }

    // Checkout handler
    const checkoutHandler = () => {
        navigate('/login/?redirect=/shipping')
    }

    // Handle loading state
    if (isLoading) {
        return <div className="text-center py-80">Loading...</div>
    }

    // Handle empty cart
    if ((!cartItems || cartItems.length === 0) && !userDetails) {
        return (
            <section className="cart-empty-section text-center py-80">
                <div className="container">
                    <div
                        className="empty-cart bg-gray-100 p-40 rounded-8"
                        onClick={() => navigate('/shop')}
                        style={{ cursor: 'pointer' }}
                    >
                        <h2 className="text-3xl fw-bold mb-16">Your cart is empty!</h2>
                        <p className="text-lg text-gray-600 mb-32">
                            Looks like you haven't added anything to your cart yet.
                        </p>
                        <Button className="btn btn-main py-12 px-32 fw-bold rounded-8">
                            Shop Now
                        </Button>
                    </div>
                </div>
            </section>
        )
    }

    // Main cart UI
    return (
        <>
            {cartItems && cartItems.length > 0 ? (
                <section className="cart pb-80">
                    <div className="container container-lg">
                        <div className="row gy-4">
                            {/* Cart Items Cards */}
                            <div className="col-xl-9 col-lg-8">
                                <div className="cart-cards-container mt-20">
                                    {cartItems.map((item) => (
                                        <div
                                            key={item._id}
                                            className="cart-card border border-gray-100 rounded-8 p-24 mb-16 d-flex align-items-center gap-24"
                                        >
                                            <Link
                                                to={`/shop/${item._id}`}
                                                className="cart-card__thumb border border-gray-100 rounded-8 flex-center"
                                                style={{ width: "100px", height: "100px" }}
                                            >
                                                <img
                                                    src={`${baseUrl}${item.images?.image1 || "/placeholder.jpg"}`}
                                                    alt={item.productName}
                                                    style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
                                                />
                                            </Link>

                                            <div className="cart-card__details flex-grow-1">
                                                <h6 className="title text-lg fw-semibold mb-8">
                                                    <Link to={`/shop/${item._id}`}>{item.productName}</Link>
                                                </h6>
                                                <div className="cart-card__info d-flex justify-content-between gap-8">
                                                    <span className="text-muted">Price: AED {(item?.finalPrice || 0).toFixed(2)}</span>
                                                    <span className="text-muted">
                                                        Subtotal: AED {(item.qty * (item?.finalPrice || 0)).toFixed(2)}
                                                    </span>
                                                </div>
                                                <div className="d-flex gap-12 mt-8">
                                                    {/* Quantity Control */}
                                                    <QuantityControl
                                                        initialQuantity={item.qty}
                                                        onQuantityChange={(newQty) => addToCartHandler(item, newQty)}
                                                    />

                                                    {/* Remove Button */}
                                                    <button
                                                        type="button"
                                                        onClick={() => removeFromCartHandler(item._id)}
                                                        className="btn p-0 text-danger"
                                                    >
                                                        <FaTrashAlt size={18} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>

                            {/* Cart Sidebar */}
                            <div className="col-xl-3 col-lg-4">
                                <div className="cart-sidebar border border-gray-100 rounded-8 px-24 py-40 mt-20">
                                    <h6>Cart Totals</h6>
                                    <div className="bg-color-three rounded-8 p-24 my-16">
                                        <div className="flex-between gap-8">
                                            <span>Total</span>
                                            <span>AED {totalPrice.toFixed(2)}</span>
                                        </div>
                                    </div>
                                    <Button
                                        type="button"
                                        className="btn btn-main py-18 w-100 rounded-8 fw-bold"
                                        onClick={checkoutHandler}
                                    >
                                        Proceed To Checkout
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="cart-empty-section text-center py-80">
                    <div className="container">
                        <div
                            className="empty-cart bg-gray-100 p-40 rounded-8"
                            onClick={() => navigate('/shop')}
                            style={{ cursor: 'pointer' }}
                        >
                            <h2 className="text-3xl fw-bold mb-16">Your cart is empty!</h2>
                            <p className="text-lg text-gray-600 mb-32">
                                Looks like you haven't added anything to your cart yet.
                            </p>
                            <Button className="btn btn-main py-12 px-32 fw-bold rounded-8">
                                Shop Now
                            </Button>
                        </div>
                    </div>
                </section>
            )}
        </>
    );

}

export default CartSection
