import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ReactSlider from 'react-slider'
import { useGetProductsQuery } from '../slices/productsApiSlice'
import Loader from './Loader'
import Message from './Message'
import { BASE_URL } from '../constants'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart as addToLocalCart } from "../slices/cartSlice"
import { useGetAllCategoryQuery } from '../slices/categoryApiSlice'
import { useGetAllBrandQuery } from '../slices/brandApiSlice'
import { useAddCartMutation } from '../slices/cartApiSlice'

const ShopSection = () => {

    let [grid, setGrid] = useState(false)

    let [active, setActive] = useState(false)
    let sidebarController = () => {
        setActive(!active)
    }

    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sort, setSort] = useState('asc')
    const [sortColumn, setSortColumn] = useState('productName')
    const [searchTerm, setSearchTerm] = useState('')


    const { data: categories } = useGetAllCategoryQuery()
    const { data: brands } = useGetAllBrandQuery()

    const [addToCart] = useAddCartMutation()


    const dispatch = useDispatch()
    const navigate = useNavigate()



    const { userDetails } = useSelector((state) => state.auth)

    // Add to cart handler
    const addToCartHandler = async (product) => {
        if (userDetails) {
            try {
                // Send cartItems as an array
                await addToCart({
                    cartItems: [{ productId: product._id, qty: 1 }],
                }).unwrap()
                navigate("/cart")
            } catch (error) {
                console.error("Failed to add to cart:", error)
            }
        } else {
            console.log(product, "123")
            dispatch(addToLocalCart(product))
            navigate("/cart")
        }
    };



    // Fetching data with pagination and query params
    const { data: products, error, refetch, isLoading } = useGetProductsQuery({
        sort,
        sortColumn,
        q: searchTerm,
        page: currentPage,
        perPage: rowsPerPage,
    })

    console.log(products, "PRODUCTS")

    const totalPages = products?.totalPages || 1 // Assuming your API response has totalPages

    // Handler for pagination clicks
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage)
        refetch()
    }

    const baseUrl = BASE_URL

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant="danger">
                    {error?.data?.message || error.error}
                </Message>
            ) : (
                <section className="shop pb-80 pt-10">
                    <div className={`side-overlay ${active && "show"}`}></div>
                    <div className="container container-lg">
                        <div className="row">


                            {/* Sidebar Start */}
                            <div className="col-lg-3">
                                <div className={`shop-sidebar ${active && "active"}`}>
                                    <button onClick={sidebarController}
                                        type="button"
                                        className="shop-sidebar__close d-lg-none d-flex w-32 h-32 flex-center border border-gray-100 rounded-circle hover-bg-main-600 position-absolute inset-inline-end-0 me-10 mt-8 hover-text-white hover-border-main-600"
                                    >
                                        <i className="ph ph-x" />
                                    </button>
                                    <div className="shop-sidebar__box border border-gray-100 rounded-8 p-24 mb-32">
                                        <h6 className="text-xl border-bottom border-gray-100 pb-24 mb-24">
                                            Product Category
                                        </h6>
                                        <ul className="max-h-540 overflow-y-auto scroll-sm">
                                            {categories.map((category) => (
                                                <li key={category._id} className="mb-24">
                                                    <Link
                                                        to={`/category/${category._id}`}
                                                        className="text-gray-900 hover-text-main-600 fw-medium"
                                                    >
                                                        {category.category}

                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32">
                                        <h6 className="text-xl border-bottom border-gray-100 pb-24 mb-24">
                                            Filter by Price
                                        </h6>
                                        <div className="custom--range">
                                            <ReactSlider
                                                className="horizontal-slider"
                                                thumbClassName="example-thumb"
                                                trackClassName="example-track"
                                                defaultValue={[0, 100]}
                                                ariaLabel={['Lower thumb', 'Upper thumb']}
                                                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                                renderThumb={(props, state) => {
                                                    const { key, ...restProps } = props
                                                    return <div {...restProps} key={state.index}>{state.valueNow}</div>
                                                }}
                                                pearling
                                                minDistance={10}
                                            />

                                            <br />
                                            <br />
                                            <div className="flex-between flex-wrap-reverse gap-8 mt-24 ">
                                                <button type="button" className="btn btn-main h-40 flex-align">
                                                    Filter{" "}
                                                </button>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32">
                                        <h6 className="text-xl border-bottom border-gray-100 pb-24 mb-24">
                                            Filter by Rating
                                        </h6>
                                        <div className="flex-align gap-8 position-relative mb-20">
                                            <label
                                                className="position-absolute w-100 h-100 cursor-pointer"
                                                htmlFor="rating5"
                                            >
                                                {" "}
                                            </label>
                                            <div className="common-check common-radio mb-0">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="rating5"
                                                />
                                            </div>
                                            <div
                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                role="progressbar"
                                                aria-label="Basic example"
                                                aria-valuenow={70}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div
                                                    className="progress-bar bg-main-600 rounded-pill"
                                                    style={{ width: "70%" }}
                                                />
                                            </div>
                                            <div className="flex-align gap-4">
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                            </div>
                                            <span className="text-gray-900 flex-shrink-0">124</span>
                                        </div>
                                        <div className="flex-align gap-8 position-relative mb-20">
                                            <label
                                                className="position-absolute w-100 h-100 cursor-pointer"
                                                htmlFor="rating4"
                                            >
                                                {" "}
                                            </label>
                                            <div className="common-check common-radio mb-0">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="rating4"
                                                />
                                            </div>
                                            <div
                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                role="progressbar"
                                                aria-label="Basic example"
                                                aria-valuenow={50}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div
                                                    className="progress-bar bg-main-600 rounded-pill"
                                                    style={{ width: "50%" }}
                                                />
                                            </div>
                                            <div className="flex-align gap-4">
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                            </div>
                                            <span className="text-gray-900 flex-shrink-0">52</span>
                                        </div>
                                        <div className="flex-align gap-8 position-relative mb-20">
                                            <label
                                                className="position-absolute w-100 h-100 cursor-pointer"
                                                htmlFor="rating3"
                                            >
                                                {" "}
                                            </label>
                                            <div className="common-check common-radio mb-0">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="rating3"
                                                />
                                            </div>
                                            <div
                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                role="progressbar"
                                                aria-label="Basic example"
                                                aria-valuenow={35}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div
                                                    className="progress-bar bg-main-600 rounded-pill"
                                                    style={{ width: "35%" }}
                                                />
                                            </div>
                                            <div className="flex-align gap-4">
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                            </div>
                                            <span className="text-gray-900 flex-shrink-0">12</span>
                                        </div>
                                        <div className="flex-align gap-8 position-relative mb-20">
                                            <label
                                                className="position-absolute w-100 h-100 cursor-pointer"
                                                htmlFor="rating2"
                                            >
                                                {" "}
                                            </label>
                                            <div className="common-check common-radio mb-0">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="rating2"
                                                />
                                            </div>
                                            <div
                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                role="progressbar"
                                                aria-label="Basic example"
                                                aria-valuenow={20}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div
                                                    className="progress-bar bg-main-600 rounded-pill"
                                                    style={{ width: "20%" }}
                                                />
                                            </div>
                                            <div className="flex-align gap-4">
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                            </div>
                                            <span className="text-gray-900 flex-shrink-0">5</span>
                                        </div>
                                        <div className="flex-align gap-8 position-relative mb-0">
                                            <label
                                                className="position-absolute w-100 h-100 cursor-pointer"
                                                htmlFor="rating1"
                                            >
                                                {" "}
                                            </label>
                                            <div className="common-check common-radio mb-0">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="rating1"
                                                />
                                            </div>
                                            <div
                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                role="progressbar"
                                                aria-label="Basic example"
                                                aria-valuenow={5}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div
                                                    className="progress-bar bg-main-600 rounded-pill"
                                                    style={{ width: "5%" }}
                                                />
                                            </div>
                                            <div className="flex-align gap-4">
                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                    <i className="ph-fill ph-star" />
                                                </span>
                                            </div>
                                            <span className="text-gray-900 flex-shrink-0">2</span>
                                        </div>
                                    </div>
                                    <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32">
                                        <h6 className="text-xl border-bottom border-gray-100 pb-24 mb-24">
                                            Filter by Brand
                                        </h6>
                                        <ul className="max-h-540 overflow-y-auto scroll-sm">
                                            {brands &&
                                                brands.map((brand) => (
                                                    <li className="mb-24" key={brand._id}>
                                                        <div className="form-check common-check common-radio">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="brand"
                                                                id={`brand-${brand._id}`}
                                                                value={brand.brand}
                                                                disabled={brand.isDisabled}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`brand-${brand._id}`}
                                                            >
                                                                {brand.brand}
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>

                                    <div className="shop-sidebar__box rounded-8">
                                        <img src="assets/images/thumbs/advertise-img1.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* Sidebar End */}




                            {/* Content Start */}
                            <div className="col-lg-9">
                                {/* Top Start */}
                                <div className="flex-between gap-16 flex-wrap mb-40 ">
                                    <span className="text-gray-900">Showing 1-20 of 85 result</span>
                                    <div className="position-relative flex-align gap-16 flex-wrap">
                                        <div className="list-grid-btns flex-align gap-16">
                                            <button onClick={() => setGrid(true)}
                                                type="button"
                                                className={`w-44 h-44 flex-center border rounded-6 text-2xl list-btn border-gray-100 ${grid === true && "border-main-600 text-white bg-main-600"}`}
                                            >
                                                <i className="ph-bold ph-list-dashes" />
                                            </button>
                                            <button onClick={() => setGrid(false)}
                                                type="button"
                                                className={`w-44 h-44 flex-center border rounded-6 text-2xl grid-btn border-gray-100 ${grid === false && "border-main-600 text-white bg-main-600"}`}
                                            >
                                                <i className="ph ph-squares-four" />
                                            </button>
                                        </div>
                                        <div className="position-relative text-gray-500 flex-align gap-4 text-14">
                                            <label htmlFor="sorting" className="text-inherit flex-shrink-0">
                                                Sort by:{" "}
                                            </label>
                                            <select defaultValue={1}
                                                className="form-control common-input px-14 py-14 text-inherit rounded-6 w-auto"
                                                id="sorting"
                                            >
                                                <option value={1} >
                                                    Popular
                                                </option>
                                                <option value={1}>Latest</option>
                                                <option value={1}>Trending</option>
                                                <option value={1}>Matches</option>
                                            </select>
                                        </div>
                                        <button onClick={sidebarController}
                                            type="button"
                                            className="w-44 h-44 d-lg-none d-flex flex-center border border-gray-100 rounded-6 text-2xl sidebar-btn"
                                        >
                                            <i className="ph-bold ph-funnel" />
                                        </button>
                                    </div>
                                </div>


                                {/* Top End */}
                                <div className={`list-grid-wrapper ${grid && "list-view"}`}>
                                    <div className="row">
                                        {products.products.map((product) => (
                                            <div
                                                key={product._id}
                                                className="col-6 col-sm-3 col-md-3 mb-4 p-1" /* Adjust grid columns */
                                            >
                                                <div className="product-card border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2 h-100">
                                                    <Link
                                                        to={`/shop/${product._id}`}
                                                        className="product-card__thumb flex-center rounded-8  position-relative"
                                                        style={{ height: '232px', width: '100%', overflow: 'hidden' }} /* Adjust width */
                                                    >
                                                        <img
                                                            src={`${baseUrl}${product.images.image1 || '/assets/images/thumbs/default.png'}`}
                                                            alt={product.productName}
                                                            className="w-100 h-100"
                                                            style={{
                                                                objectFit: 'contain',
                                                                objectPosition: 'center',
                                                            }}
                                                        />

                                                        {product.discountInPercentage > 0 && (
                                                            <span className="product-card__badge bg-primary-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                                                -{product.discountInPercentage}%
                                                            </span>
                                                        )}
                                                    </Link>
                                                    <div className="product-card__content p-16 col-md-12">
                                                        <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                                            <Link
                                                                to={`/shop/${product._id}`}
                                                                className="link text-line-2"
                                                                tabIndex={0}
                                                            >
                                                                {product.productName}
                                                            </Link>
                                                        </h6>
                                                        <div className="flex-align mb-20 mt-16 gap-6">
                                                            <span className="text-xs fw-medium text-gray-500">4.8</span>
                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                <i className="ph-fill ph-star" />
                                                            </span>
                                                            <span className="text-xs fw-medium text-gray-500">(17k)</span>
                                                        </div>
                                                        <div className="product-card__price my-20">
                                                            <span className="text-heading text-md fw-semibold">
                                                                AED {product.finalPrice} <span className="text-gray-500 fw-normal"></span>
                                                            </span>
                                                            {product.discountInPercentage > 0 && (
                                                                <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                                    {product.price}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <Link
                                                            className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                                            onClick={() => addToCartHandler(product)}
                                                            tabIndex={0}
                                                        >
                                                            Add To Cart <i className="ph ph-shopping-cart" />
                                                        </Link>
                                                    </div>





                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>




                                {/* Pagination Component */}
                                <ul className="pagination flex-center flex-wrap gap-16">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <Link
                                            className="page-link h-64 w-64 flex-center text-xxl rounded-8 fw-medium text-neutral-600 border border-gray-100"
                                            to="#"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        >
                                            <i className="ph-bold ph-arrow-left" />
                                        </Link>
                                    </li>

                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                            <Link
                                                className="page-link h-64 w-64 flex-center text-md rounded-8 fw-medium text-neutral-600 border border-gray-100"
                                                to="#"
                                                onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1 < 10 ? `${index + 1}` : index + 1}
                                            </Link>
                                        </li>
                                    ))}

                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                        <Link
                                            className="page-link h-64 w-64 flex-center text-xxl rounded-8 fw-medium text-neutral-600 border border-gray-100"
                                            to="#"
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        >
                                            <i className="ph-bold ph-arrow-right" />
                                        </Link>
                                    </li>
                                </ul>
                                {/* Pagination End */}
                            </div>
                            {/* Content End */}
                        </div>
                    </div>
                </section>
            )}
        </>

    )
}

export default ShopSection