import React from 'react'

const Wishlist = () => {
  return (
    <div>
      <>Hi</>
    </div>
  )
}

export default Wishlist
