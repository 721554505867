import React, { useEffect, useRef, useState } from "react"
import "ol/ol.css"
import "ol-ext/dist/ol-ext.css" // Import ol-ext styles
import { Map, View } from "ol"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import OSM from "ol/source/OSM"
import XYZ from "ol/source/XYZ"
import { fromLonLat, toLonLat } from "ol/proj"
import { Feature } from "ol"
import { Point } from "ol/geom"
import { Style, Icon } from "ol/style"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SearchControl from "ol-ext/control/SearchNominatim"

const saudiArabiaBoundingBox = {
    minLon: 34.572765,
    minLat: 16.315399,
    maxLon: 55.666569,
    maxLat: 32.231532,
}

const DeliveryMap = () => {
  
    return (
       <>
       123
       </>
    )
}

export default DeliveryMap
