import { SUBCATEGORY_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const subcategoryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllSubcategory: builder.query({
            query: () => ({
                url: SUBCATEGORY_URL
            }),
            providesTags: ['Subcategory'],
            keepUnusedDataFor: 5,
        }),
    })
})

export const { useGetAllSubcategoryQuery } = subcategoryApiSlice