import { PINCODE_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const pincodeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllPincode: builder.query({
            query: () => ({
                url: PINCODE_URL
            }),
            providesTags: ['Pincode'],
            keepUnusedDataFor: 5,
        }),
        getPincode: builder.query({
            query: ({ q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'pincode', isDeliverable = null }) => ({
                url: `${PINCODE_URL}/data`,
                params: {
                    q,
                    page,
                    perPage,
                    sort,
                    sortColumn,
                    isDeliverable
                }
            }),
            keepUnusedDataFor: 5,
        }),
        getPincodeById: builder.query({
            query: (id) => ({
                url: `${PINCODE_URL}/${id}`
            }),
        }),
        availablePincode: builder.mutation({
            query: (data) => ({
                url: `${PINCODE_URL}/available`,
                method: 'POST',
                body: data
            })
        }),
        addPincode: builder.mutation({
            query: (data) => ({
                url: `${PINCODE_URL}/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Pincode']
        }),
        editPincode: builder.mutation({
            query: (data) => ({
                url: `${PINCODE_URL}/${data.id}`,
                method: 'PATCH',
                body: data
            })
        }),
        deletePincode: builder.mutation({
            query: (data) => ({
                url: `${PINCODE_URL}/delete/${data.id}`,
                method: 'PATCH',
                body: data
            })
        }),
    })
})

export const {
    useGetAllPincodeQuery,
    useGetPincodeQuery,
    useGetPincodeByIdQuery,
    useAddPincodeMutation,
    useEditPincodeMutation,
    useDeletePincodeMutation,
    useAvailablePincodeMutation
} = pincodeApiSlice