import React from 'react'

const PersonalDetails = () => {
  return (
    <div>
      Personal
    </div>
  )
}

export default PersonalDetails
