import React, { useEffect, useState } from 'react'
import { useRegisterUserMutation } from '../slices/userApiSlice'
import { toast } from 'react-toastify'
import { setCredentials } from '../slices/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import GoogleAuth from './GoogleAuth'

const Register = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [validationMessage, setValidationMessage] = useState('')
    const [isValid, setIsValid] = useState(true)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [register] = useRegisterUserMutation()

    const { userDetails } = useSelector((state) => state.auth)
    const { search } = useLocation()
    const sp = new URLSearchParams(search)
    const redirect = sp.get('redirect') || '/'

    useEffect(() => {
        if (userDetails) {
            navigate(redirect)
        }
    }, [userDetails, redirect, navigate])

    // Validate email and phone
    const handleInputChange = (e) => {
        const value = e.target.value
        setPhone(value)
        validateInput(value)
    }

    const validateInput = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const phoneRegex = /^\+?(\d{1,4})?[-\s]?\(?(\d{1,3})\)?[-\s]?(\d{4,10})$/

        if (emailRegex.test(value)) {
            setValidationMessage('')
            setIsValid(true)
        } else if (phoneRegex.test(value)) {
            setValidationMessage('')
            setIsValid(true)
        } else {
            setIsValid(false)
            setValidationMessage('Please enter a valid phone number with country code or a valid email.')
        }
    }

    const handlePasswordChange = (e) => {
        const password = e.target.value
        setPassword(password)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (password !== confirmPassword) {
            toast.error('Passwords do not match')
            return
        }

        if (password.length < 8) {
            toast.error('Password must be at least 8 characters long')
            return
        }

        if (!email || !password || !confirmPassword || !username) {
            toast.error('Please fill in all required fields')
            return
        }

        const userData = { username, email, phone, password, confirmPassword }

        console.log(userData, "USERDATA")

        try {
            const res = await register(userData).unwrap()
            dispatch(setCredentials({ ...res }))
            toast.success("Registration successful!")
        } catch (error) {
            if (error?.data?.message) {
                toast.error(error.data.message)
            } else {
                toast.error("Registration failed")
            }
        }
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible)
    }

    return (
        <section className="account pb-40 pt-20">
            <div className="container container-lg">
                <form onSubmit={handleSubmit}>
                    <div className="row gy-4 d-flex justify-content-center">
                        <div className="col-xl-6">
                            <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                                <h6 className="text-xl mb-32">Register</h6>

                                {/* Email Field */}
                                <div className="row">
                                    <div className="col">
                                        <div className="mb-24">
                                            <label htmlFor="email" className="text-neutral-900 mb-8 fw-medium">
                                                Email <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="common-input"
                                                id="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Username and Password Fields */}
                                <div className="row">
                                    <div className="col">
                                        {/* Username Field */}
                                        <div className="mb-24">
                                            <label htmlFor="username" className="text-neutral-900 mb-8 fw-medium">
                                                Username <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="common-input"
                                                id="username"
                                                placeholder="Username"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </div>

                                        {/* Password Field */}
                                        <div className="mb-24 position-relative">
                                            <label htmlFor="password" className="text-neutral-900 mb-8 fw-medium">
                                                Password <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type={passwordVisible ? "text" : "password"}
                                                className="common-input"
                                                id="password"
                                                placeholder="Enter Password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                            />
                                            <span
                                                className="password-eye-icon"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col">
                                        {/* Phone Field (optional) */}
                                        <div className="mb-24">
                                            <label htmlFor="phone" className="text-neutral-900 mb-8 fw-medium">
                                                Phone (Optional)
                                            </label>
                                            <input
                                                type="text"
                                                className="common-input"
                                                id="phone"
                                                placeholder="Phone with code"
                                                value={phone}
                                                onChange={handleInputChange}
                                            />
                                            <div className="text-danger">{validationMessage}</div>
                                        </div>

                                        {/* Confirm Password Field */}
                                        <div className="mb-24 position-relative">
                                            <label htmlFor="confirmPassword" className="text-neutral-900 mb-8 fw-medium">
                                                Confirm Password <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type={confirmPasswordVisible ? "text" : "password"}
                                                className="common-input"
                                                id="confirmPassword"
                                                placeholder="Confirm Password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            <span
                                                className="password-eye-icon"
                                                onClick={toggleConfirmPasswordVisibility}
                                            >
                                                {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className="mb-215">
                                    <button type="submit" className="btn btn-main py-18 px-40 me-30">
                                        Register
                                    </button>
                                    <span>Already have an Account?{' '}
                                        <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                                            Login
                                        </Link></span>
                                </div>
                                <div className='text-center py-5 fw-bold mb-5'>or</div>
                                <div className='text-center'>
                                    <GoogleAuth />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Register
