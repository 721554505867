import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

const initialState = {
    userDetails: localStorage.getItem('userDetails')
        ? JSON.parse(localStorage.getItem('userDetails'))
        : null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            state.userDetails = action.payload
            localStorage.setItem('userDetails', JSON.stringify(action.payload))
        },
        logout: (state, action) => {
            state.userDetails = null
            localStorage.removeItem('userDetails')
            toast.info('Session expired. Please log in again.');
        },
    },
})

export const { setCredentials, logout } = authSlice.actions

export default authSlice.reducer
