import React, { useState } from "react"
import Preloader from "../helper/Preloader"

import HeaderTwo from "../components/HeaderTwo"
import Breadcrumb from "../components/Breadcrumb"
import FooterTwo from "../components/FooterTwo"
import BottomFooter from "../components/BottomFooter"
import ShippingOne from "../components/ShippingOne"
import Account from "../components/Account"
import ScrollToTop from "react-scroll-to-top"
import ColorInit from "../helper/ColorInit"
import SubHeader from "../components/SubHeader"
import Wishlist from "../components/Wishlist"
import Sidebar from "../components/Sidebar"

const WishlistPage = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)


    return (
        <>
            {/* ColorInit */}
            <ColorInit color={true} />

            {/* ScrollToTop */}
            {/* <ScrollToTop smooth color="#FA6400" /> */}

            {/* Preloader */}
            <Preloader />

            {/* HeaderTwo */}
            <HeaderTwo category={true} />

            {/* SubHeader */}
            <SubHeader onSidebarToggle={toggleSidebar} />

            {/* Sidebar */}
            <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />

            {/* Account */}
            <Wishlist />

            {/* ShippingOne */}
            <ShippingOne />

            {/* FooterTwo */}
            <FooterTwo />

            {/* BottomFooter */}
            <BottomFooter />


        </>
    )
}

export default WishlistPage
