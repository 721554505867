import React, { useEffect, useRef } from "react"
import { FaTimes, FaSignOutAlt } from "react-icons/fa"
import { Button, Nav, Accordion } from "react-bootstrap"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { logout } from "../slices/authSlice"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"

const Sidebar = ({ isOpen, onClose }) => {
    const { userDetails } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const sidebarRef = useRef(null)
    const imageUrl = process.env.REACT_APP_URL

    const logoutHandler = async () => {
        try {
            dispatch(logout())
            navigate("/login")
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                onClose()
            }
        }

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside)
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isOpen, onClose])

    return (
        <div ref={sidebarRef} className={`sidebar ${isOpen ? "open" : ""}`} style={{ zIndex: 9999 }} >
            {isOpen && (
                <button onClick={onClose} className="sidebar-close p-0" style={{ zIndex: 9999 }}>
                    <FaTimes className="white-icon" />
                </button>
            )}

            {userDetails ? (
                <>
                    <Nav.Link as={Link} to="/profile" className="no-style-navlink">
                        <div className="sidebar-header">
                            {/* <div className="image-container">
                                <img src={imageUrl} alt="Hawaseeb" className="profile-image" />
                            </div> */}
                            <div className="sidebar-username">
                                {userDetails.name && (
                                    <div className="sidebar-name">
                                        Welcome {userDetails.name}                                    </div>
                                )}
                            </div>
                        </div>
                    </Nav.Link>
                    <Nav className="flex-column">
                        <>
                            <Nav.Link
                                as={Link}
                                to="/orders"
                                className={classNames("sidebar-link", {
                                    "active-link": location.pathname === "/orders",
                                })}
                            >
                                Orders
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/wishlist"
                                className={classNames("sidebar-link", {
                                    "active-link": location.pathname === "/wishlist",
                                })}
                            >
                                Wishlist
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/profile"
                                className={classNames("sidebar-link", {
                                    "active-link": location.pathname === "/profile",
                                })}
                            >
                                Profile
                            </Nav.Link>
                        </>
                    </Nav>
                    <div className="sidebar-footer">
                        <button onClick={logoutHandler} className="sidebar-logout">
                            <FaSignOutAlt className="white-icon" /> Logout
                        </button>
                    </div>
                </>
            ) : (
                <div className="align-items-center d-flex justify-content-center flex-column col-12 h-100 w-100">
                    <p className="text-center mb-5">
                        Login to View Your Orders and Unlock Exclusive Offers !!!
                    </p>
                    <button onClick={onClose} className="sidebar-logout">
                        <Button
                            as={Link}
                            to="/login"
                            className={classNames("btn-main sidebar-link", {
                                "active-link": location.pathname === "/login",
                            })}
                        >
                            <FaSignOutAlt className="white-icon" /> Login
                        </Button>
                    </button>
                </div>
            )}
        </div>
    )
}

export default Sidebar
