import React, { useEffect, useState } from 'react'
import { useLoginUserMutation } from '../slices/userApiSlice'
import { toast } from 'react-toastify'
import { setCredentials } from '../slices/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import GoogleAuth from './GoogleAuth'
import { useAddCartMutation } from '../slices/cartApiSlice'
import { clearCartItems } from '../slices/cartSlice'

const Login = () => {
    const [password, setPassword] = useState('')
    const [emailOrPhone, setEmailOrPhone] = useState('')
    const [validationMessage, setValidationMessage] = useState('')
    const [isValid, setIsValid] = useState(true)

    const [addCart] = useAddCartMutation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [login] = useLoginUserMutation()
    const { userDetails } = useSelector((state) => state.auth)
    const { search } = useLocation()
    const sp = new URLSearchParams(search)
    const redirect = sp.get('redirect') || '/'

    useEffect(() => {
        if (userDetails && window.location.pathname !== redirect) {
            navigate(redirect)
        }
    }, [userDetails, redirect, navigate])


    const handleInputChange = (e) => {
        const value = e.target.value
        setEmailOrPhone(value)
        validateInput(value)
    }

    const validateInput = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const phoneRegex = /^\d{10,}$/

        if (phoneRegex.test(value) || emailRegex.test(value)) {
            setValidationMessage('')
            setIsValid(true)
        } else {
            setIsValid(false)
            if (!value.includes('@') && isNaN(value)) {
                setValidationMessage('Email or phone number required.')
            } else if (!emailRegex.test(value) && value.includes('@')) {
                setValidationMessage('Invalid email format.')
            } else if (!phoneRegex.test(value)) {
                setValidationMessage('Invalid phone number.')
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!emailOrPhone) {
            toast.error('Email or Phone number is required')
            return
        }

        if (!password) {
            toast.error('Password is required')
            return
        }

        const userData = {
            emailOrPhone,
            password,
        }

        try {
            // Login user
            const res = await login(userData).unwrap()
            dispatch(setCredentials({ ...res }))
            toast.success("Login Successful!")
            setEmailOrPhone('')
            setPassword('')

            // Extract cartItems from local storage
            const cart = JSON.parse(localStorage.getItem("cart"))
            if (cart?.cartItems?.length) {
                // Send all cart items to the backend in a single request
                const cartData = cart.cartItems.map((item) => ({
                    productId: item._id,
                    qty: item.qty,
                    newCart: true,
                }))

                // Send cart data to the backend
                await addCart({ cartItems: cartData }).unwrap()
                console.log("All cart items sent")

                // Optionally clear local storage after sending data
                localStorage.removeItem("cart")

                // Clear Redux cart state as well
                dispatch(clearCartItems())
            }

            navigate(redirect) // Navigate to the redirected path
        } catch (error) {
            toast.error(error?.data?.message || error.error || "Login Failed")
        }
    }

    return (
        <section className="account pb-40 pt-22">
            <div className="container container-lg">
                <form onSubmit={handleSubmit}>
                    <div className="row gy-4 d-flex justify-content-center">
                        <div className="col-xl-6">
                            <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                                <>
                                    <h6 className="text-xl mb-32">Login</h6>
                                    <div className="mb-24">
                                        <label htmlFor="emailOrPhone" className="text-neutral-900 text-lg mb-8 fw-medium">
                                            Phone or Email address <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="emailOrPhone"
                                            placeholder="Phone or Email"
                                            value={emailOrPhone}
                                            onChange={handleInputChange}
                                        />
                                        <div className="text-danger">{validationMessage}</div>
                                    </div>
                                    <div className="mb-18">
                                        <label htmlFor="password" className="text-neutral-900 text-lg mb-8 fw-medium">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="common-input"
                                            id="password"
                                            placeholder="Enter Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <span className='mt-2 d-flex justify-content-end'>
                                            <Link to={redirect ? `/forgot?redirect=${redirect}` : '/forgot'}>Forgot Password ?</Link>
                                        </span>
                                    </div>
                                    <div className="mb-15">
                                        <button type="submit" className="btn btn-main py-18 px-40 me-30">
                                            Log in
                                        </button>
                                        <span>New Customer?{' '}
                                            <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                                                Register
                                            </Link>
                                        </span>
                                    </div>
                                    <div className='text-center py-5 fw-bold mb-5'>or</div>
                                    <div className='text-center'>
                                        <GoogleAuth />
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Login
