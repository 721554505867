import React, { useEffect, useState, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Row, Col, ListGroup, Image, Card, Accordion, Form } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useGetCartQuery } from "../slices/cartApiSlice"
import "ol/ol.css" // OpenLayers styles
import Map from "ol/Map"
import View from "ol/View"
import TileLayer from "ol/layer/Tile"
import OSM from "ol/source/OSM"
import { fromLonLat } from "ol/proj"
import Feature from "ol/Feature"
import Point from "ol/geom/Point"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import { Style, Icon } from "ol/style"
import { useAddCheckoutMutation } from "../slices/checkoutApiSlice"

const Checkout = () => {
    const navigate = useNavigate()
    const [paymentMethodId, setPaymentMethodId] = useState("")

    const { data: cart, error, isLoading } = useGetCartQuery()
    const [checkout] = useAddCheckoutMutation()
    const [activeAccordionKey, setActiveAccordionKey] = useState("0")

    const [selectedPayment, setSelectedPayment] = useState("payment1")
    const mapRef = useRef(null)

    const handlePlaceOrder = async () => {
        let paymentMethod = ""

        // Map the selectedPayment state to a readable string
        if (selectedPayment === "payment1") paymentMethod = "Online"
        if (selectedPayment === "payment2") paymentMethod = "COD"
        if (selectedPayment === "payment3") paymentMethod = "PayPal"

        if (!paymentMethod) {
            toast.error("Please select a valid payment method.")
            return
        }

        // try {
            // Call the checkout mutation with the payment method string
            const response = await checkout({ paymentMethodId: paymentMethod }).unwrap()
            toast.success("Order placed successfully!")
            navigate("/orders")
        // } catch (err) {
        //     toast.error(err.data?.message || "An error occurred during checkout.")
        // }
    };


    const handleNextAccordion = (nextKey) => {
        setActiveAccordionKey(nextKey)
    }

    // Map initialization
    useEffect(() => {
        if (cart?.deliveryLocation?.longitude && cart?.deliveryLocation?.latitude) {
            const coordinates = [
                parseFloat(cart.deliveryLocation.longitude),
                parseFloat(cart.deliveryLocation.latitude),
            ]

            const map = new Map({
                target: mapRef.current,
                layers: [
                    new TileLayer({
                        source: new OSM(),
                    }),
                ],
                view: new View({
                    center: fromLonLat(coordinates),
                    zoom: 12,
                }),
            })

            const marker = new Feature({
                geometry: new Point(fromLonLat(coordinates)),
            })

            marker.setStyle(
                new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        src: "https://cdn-icons-png.flaticon.com/512/684/684908.png",
                        scale: 0.05,
                    }),
                })
            )

            const vectorLayer = new VectorLayer({
                source: new VectorSource({
                    features: [marker],
                }),
            })

            map.addLayer(vectorLayer)

            return () => map.setTarget(null)
        }
    }, [cart])

    if (isLoading) {
        return <p>Loading...</p>
    }

    if (error) {
        return <p className="text-danger">Error fetching cart: {error.message}</p>
    }

    return (
        <div className="container my-4">
            <Row>
                <Col md={12}>
                    <Accordion defaultActiveKey="0" activeKey={activeAccordionKey} onSelect={(e) => setActiveAccordionKey(e)}>
                        {/* Order Items Section */}
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Order Items</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    {/* Left Section: Order Items */}
                                    <Col md={8}>
                                        {cart?.cartItems?.length === 0 ? (
                                            <p>Your cart is empty</p>
                                        ) : (
                                            <ListGroup variant="flush">
                                                {cart.cartItems.map((item) => (
                                                    <ListGroup.Item key={item._id}>
                                                        <Row className="align-items-center">
                                                            <Col md={3}>
                                                                <Image
                                                                    src={item.product.images.image1}
                                                                    alt={item.product.productName}
                                                                    fluid
                                                                    rounded
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Link to={`/product/${item.product._id}`}>
                                                                    {item.product.productName}
                                                                </Link>
                                                            </Col>
                                                            <Col md={4}>
                                                                {item.qty} x AED {item.product.finalPrice} = AED{" "}
                                                                {item.qty * item.product.finalPrice}
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        )}
                                    </Col>

                                    {/* Right Section: Order Summary */}
                                    <Col md={4}>
                                        <Card>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col>Items</Col>
                                                        <Col className="text-end">AED {cart?.itemsPrice || 0}</Col>
                                                    </Row>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col>Shipping</Col>
                                                        <Col className="text-end">AED {cart?.shippingPrice || 0}</Col>
                                                    </Row>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col>Tax</Col>
                                                        <Col className="text-end">AED {cart?.taxPrice || 0}</Col>
                                                    </Row>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col>Total</Col>
                                                        <Col className="text-end">
                                                            <strong>AED {cart?.totalPrice || 0}</strong>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <Button
                                                        type="button"
                                                        className="btn-main w-100"
                                                        onClick={() => handleNextAccordion("1")}
                                                    >
                                                        Next
                                                    </Button>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Card>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>

                        {/* Delivery Location & Shipping */}
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Delivery Location & Shipping</Accordion.Header>
                            <Accordion.Body>
                                <ListGroup>
                                    <ListGroup.Item>
                                        <p><strong>Address:</strong> {cart?.shippingAddress?.address || "N/A"}</p>
                                        <p><strong>State:</strong> {cart?.shippingAddress?.state || "N/A"}</p>
                                        <p><strong>Zipcode:</strong> {cart?.shippingAddress?.zipcode || "N/A"}</p>
                                        <p><strong>Phone:</strong> {cart?.shippingAddress?.phone || "N/A"}</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div
                                            ref={mapRef}
                                            style={{ width: "100%", height: "300px", borderRadius: "8px" }}
                                        ></div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Button
                                            type="button"
                                            className="btn-main w-100"
                                            onClick={() => handleNextAccordion("2")}
                                        >
                                            Next
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>

                        {/* Payment Method */}
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Payment Method</Accordion.Header>
                            <Accordion.Body>
                                <Form>
                                    <Form.Check
                                        type="radio"
                                        id="payment2"
                                        label="Cash on Delivery"
                                        checked={selectedPayment === "payment2"}
                                        onChange={(e) => setSelectedPayment(e.target.id)}
                                    />
                                    <Form.Check
                                        type="radio"
                                        disabled
                                        id="payment1"
                                        label="Credit/Debit Card"
                                        checked={selectedPayment === "payment1"}
                                        onChange={(e) => setSelectedPayment(e.target.id)}
                                    />
                                    <Form.Check
                                        type="radio"
                                        disabled
                                        id="payment3"
                                        label="PayPal"
                                        checked={selectedPayment === "payment3"}
                                        onChange={(e) => setSelectedPayment(e.target.id)}
                                    />
                                </Form>
                                <Button
                                    type="button"
                                    className="btn-main w-100"
                                    disabled={!cart?.cartItems || cart.cartItems.length === 0}
                                    onClick={handlePlaceOrder}
                                >
                                    Place Order
                                </Button>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </div>
    )
}

export default Checkout
