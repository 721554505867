import { CHECKOUT_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const checkoutSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addCheckout: builder.mutation({
            query: (data) => ({
                url: CHECKOUT_URL,
                method: 'POST',
                body: data,
                credentials: 'include',
            }),
        }),
    }),
})

export const { useAddCheckoutMutation } = checkoutSlice
