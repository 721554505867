import { Route, Routes } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import RouteScrollToTop from "./helper/RouteScrollToTop"
import PhosphorIconInit from "./helper/PhosphorIconInit"
import HomePageTwo from "./pages/HomePageTwo"
import ShopPage from "./pages/ShopPage"
import ProductDetailsPageOne from "./pages/ProductDetailsPageOne"
import ProductDetailsPageTwo from "./pages/ProductDetailsPageTwo"
import CartPage from "./pages/CartPage"
import CheckoutPage from "./pages/CheckoutPage"
import AccountPage from "./pages/AccountPage"
import BlogPage from "./pages/BlogPage"
import BlogDetailsPage from "./pages/BlogDetailsPage"
import ContactPage from "./pages/ContactPage"
import LoginPage from "./pages/auth/LoginPage"
import RegisterPage from "./pages/auth/RegisterPage"
import ShippingAddressPage from "./pages/ShippingAddressPage"
import DeliveryMap from "./pages/DeliveryMap"
import PrivateRoute from "./components/PrivateRoute"
import GoogleAuth from "./components/GoogleAuth"
import VerifyOtpPage from "./pages/auth/VerifyOtpPage"
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage"
import OrdersPage from "./pages/OrdersPage"
import WishlistPage from "./pages/WishlistPage"
import ProfilePage from "./pages/ProfilePage"
import OrderDetailPage from "./pages/OrderDetailPage"

function App() {


  return (
    <>
      {/* ToastContainer for global notifications */}
      <ToastContainer />
      <RouteScrollToTop />
      <PhosphorIconInit />

      <Routes>
        <Route exact path="/" element={<HomePageTwo />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/google" element={<GoogleAuth />} />
        <Route exact path="/register" element={<RegisterPage />} />
        <Route exact path="/forgot" element={<ForgotPasswordPage />} />
        <Route exact path="/verify" element={<VerifyOtpPage />} />
        <Route exact path="/shop" element={<ShopPage />} />
        <Route exact path="/shop/:id" element={<ProductDetailsPageTwo />} />
        <Route exact path="/cart" element={<CartPage />} />
        <Route exact path="/contact" element={<ContactPage />} />

        {/* Not Tested */}
        <Route exact path="/shop/shoptwo" element={<ProductDetailsPageOne />} />
        <Route exact path="/account" element={<AccountPage />} />
        <Route exact path="/blog" element={<BlogPage />} />
        <Route exact path="/blog-details" element={<BlogDetailsPage />} />

        {/* Testing routes */}
        <Route exact path="/map" element={<DeliveryMap />} />


        <Route path="" element={<PrivateRoute />}>
          <Route exact path="/checkout" element={<CheckoutPage />} />
          <Route exact path="/shipping" element={<ShippingAddressPage />} />
          <Route exact path="/orders" element={<OrdersPage />} />
          <Route exact path="/wishlist" element={<WishlistPage />} />
          <Route exact path="/profile" element={<ProfilePage />} />
          <Route path="/order/:id" element={<OrderDetailPage />} />

        </Route>

      </Routes>
    </>
  )
}

export default App
