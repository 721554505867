import React from "react"
import { useParams } from "react-router-dom"
import { useGetOrderByIdQuery } from "../slices/orderApiSlice"
import { Card, Col, ListGroup, Row } from "react-bootstrap"

const OrderDetail = () => {
    const { id } = useParams()
    const { data: order, error, isLoading } = useGetOrderByIdQuery(id)

    console.log(order, "ORDER")

    const statuses = ["Placed", "Shipped", "Delivered", "Returned", "Cancelled"]

    return (
        <div className="container mt-4">
            <h5>Order Details</h5>

            {isLoading && <p>Loading...</p>}
            {error && <p className="text-danger">Error fetching order details.</p>}

            {order && (
                <div className="d-flex justify-content-around">
                    <div className="timeline col-md-4">
                        {statuses.map((status, index) => (
                            <div className="timeline-event" key={status}>
                                <div
                                    className={`timeline-dot ${status === order.status ? "active" : ""
                                        }`}
                                ></div>
                                <div className="timeline-content">
                                    <h5>{status}</h5>
                                    <p>
                                        {status === "Placed"
                                            ? `Order placed on ${new Date(
                                                order.orderPlacedOn
                                            ).toLocaleDateString()}`
                                            : status === "Shipped"
                                                ? `Shipped towards delivery`
                                                : status === "Delivered"
                                                    ? `Expected Delivery ${new Date(
                                                        order.expectedDeliveryDate
                                                    ).toLocaleDateString()}`
                                                    : "Status not available"}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col-md-8 d-flex p-5 justify-content-end">
                        <Card className="align-items-center justify-content-center">
                            <ListGroup variant="flush">
                                {/* Product Details */}
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Product</Col>
                                        <Col className="text-end">{order?.product?.productName}</Col>
                                    </Row>
                                </ListGroup.Item>

                                {/* Price and Quantity */}
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Price</Col>
                                        <Col className="text-end">AED {order?.product?.price}</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Quantity</Col>
                                        <Col className="text-end">{order?.qty}</Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Subtotal</Col>
                                        <Col className="text-end">AED {order?.subTotal}</Col>
                                    </Row>
                                </ListGroup.Item>

                                {/* Shipping Details */}
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Shipping</Col>
                                        <Col className="text-end">AED {order?.shippingPrice || 0}</Col>
                                    </Row>
                                </ListGroup.Item>

                                {/* Tax Details */}
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Tax</Col>
                                        <Col className="text-end">AED {order?.taxPrice || 0}</Col>
                                    </Row>
                                </ListGroup.Item>

                                {/* Total Amount */}
                                <ListGroup.Item>
                                    <Row>
                                        <Col><strong>Total</strong></Col>
                                        <Col className="text-end"><strong>AED {order?.grandTotal}</strong></Col>
                                    </Row>
                                </ListGroup.Item>

                                {/* Payment Status */}
                               
                            </ListGroup>
                        </Card>
                    </div>
                </div>

            )}
        </div>
    )
}

export default OrderDetail
