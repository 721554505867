import { BANNER_URL, DEAL_URL, FEATURED_URL, POPULAR_PRODUCTS_URL, PROMOTIONAL_URL, RECOMMENDED_URL, TOPSELLING_URL, TRENDING_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const bannerApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBanner: builder.query({
            query: () => ({
                url: BANNER_URL
            }),
            providesTags: ['Banner'],
            keepUnusedDataFor: 5,
        }),
        getDealOfTheDay: builder.query({
            query: () => ({
                url: DEAL_URL
            }),
            providesTags: ['Deal'],
            keepUnusedDataFor: 5,
        }),
        getPromotional: builder.query({
            query: () => ({
                url: PROMOTIONAL_URL
            }),
            providesTags: ['Promotional'],
            keepUnusedDataFor: 5,
        }),
        getTopSelling: builder.query({
            query: () => ({
                url: TOPSELLING_URL
            }),
            providesTags: ['Topselling'],
            keepUnusedDataFor: 5,
        }),
        getTrending: builder.query({
            query: () => ({
                url: TRENDING_URL
            }),
            providesTags: ['Trending'],
            keepUnusedDataFor: 5,
        }),
        getFeatured: builder.query({
            query: () => ({
                url: FEATURED_URL
            }),
            providesTags: ['Featured'],
            keepUnusedDataFor: 5,
        }),
        getRecommended: builder.query({
            query: () => ({
                url: RECOMMENDED_URL
            }),
            providesTags: ['Recommended'],
            keepUnusedDataFor: 5,
        }),
        getPopularProducts: builder.query({
            query: () => ({
                url: POPULAR_PRODUCTS_URL
            }),
            providesTags: ['Popular'],
            keepUnusedDataFor: 5,
        }),
    })
})

export const {
    useGetBannerQuery, useGetDealOfTheDayQuery, useGetPromotionalQuery, useGetTopSellingQuery, useGetTrendingQuery, useGetFeaturedQuery, useGetRecommendedQuery, useGetPopularProductsQuery
} = bannerApiSlice