import React from "react"
import { Outlet, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
const PrivateRoute = () => {
    const { userDetails } = useSelector((state) => state.auth)

    return userDetails ? <Outlet /> : <Navigate to="/login" replace />

}

export default PrivateRoute