import React from 'react'
import { Link } from 'react-router-dom'

const BottomFooter = () => {
    return (
        <div className="bottom-footer bg-color-one py-8">
            <div className="container container-lg">
                <div className="bottom-footer__inner flex-between flex-wrap gap-16 py-16">
                    <p className="bottom-footer__text ">
                        Luvid Technologies © 2024. All Rights Reserved{" "}
                    </p>
                    <ul className="flex-align gap-16">
                        <div className="flex-align gap-8 flex-wrap">
                            <span className="text-heading text-sm">Follow us on</span>
                        </div>
                        <li>
                            <Link
                                to="/https://www.facebook.com"
                                className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                            >
                                <i className="ph-fill ph-facebook-logo" />
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/https://www.twitter.com"
                                className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                            >
                                <i className="ph-fill ph-twitter-logo" />
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/https://www.linkedin.com"
                                className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                            >
                                <i className="ph-fill ph-instagram-logo" />
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/https://www.pinterest.com"
                                className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                            >
                                <i className="ph-fill ph-linkedin-logo" />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default BottomFooter