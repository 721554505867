import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick'
import { getCountdown } from '../helper/Countdown'
import { useGetProductByIdQuery } from '../slices/productsApiSlice'
import { Loader } from 'react-feather'
import Message from './Message'
import { BASE_URL } from '../constants'
import { useAvailablePincodeMutation, useGetPincodeQuery } from '../slices/pincodeApiSlice'
import { toast } from 'react-toastify'
import { addToCart } from '../slices/cartSlice'
import { useDispatch } from 'react-redux'

const ProductDetailsTwo = () => {
    const [pincode, setPincode] = useState('')
    const [deliveryCharge, setDeliveryCharge] = useState('Free')
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState('N/A')

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id: productId } = useParams()

    const { data: product, isLoading, refetch, error } = useGetProductByIdQuery(productId)

    const baseUrl = BASE_URL

    const [mainImage, setMainImage] = useState("")


    useEffect(() => {
        if (product?.images?.length) {
            setMainImage(`${baseUrl}${product.images[0]?.url}`)
        }
    }, [product])

    useEffect(() => {
        if (product?.deliveryPeriod) {
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() + parseInt(product.deliveryPeriod))

            const formattedDate = currentDate.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            })

            setExpectedDeliveryDate(formattedDate)
        }
    }, [product])

    const inStock = product?.inStock || 0
    const finalPrice = product?.finalPrice || 0

    const [quantity, setQuantity] = useState(1)

    const incrementQuantity = () => {
        if (quantity < inStock) {
            setQuantity(quantity + 1)
        }
    }

    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }

    const totalPrice = finalPrice * quantity


    const addToCartHandler = () => {
        if (product && quantity > 0) {
            console.log(quantity, "QUANTITY")
            dispatch(addToCart({ product, qty: quantity }))
            navigate('/cart')
        }
    }


    return (
        <section className="product-details py-80">
            <div className="container container-lg">
                <div className="row gy-4">


                    {isLoading ? (
                        <Loader />
                    ) : error ? (
                        <Message variant="danger">
                            {error?.data?.message || error.error}
                        </Message>
                    ) : (
                        <div className="col-xl-9">
                            <div className="row gy-4">
                                <div className="col-xl-6">

                                    <div className="row">
                                        <div className="product-details__left">
                                            <div className="product-details__thumb-slider border border-gray-100">
                                                <div className="">
                                                    <div className="product-details__thumb flex-center h-100">
                                                        <img
                                                            src={
                                                                mainImage || `${baseUrl}${product.images.image1 || '/assets/images/thumbs/default.png'}`
                                                            }
                                                            alt="Main Product"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-24">
                                                <div className="product-details__images-slider d-flex justify-content-around">
                                                    {Object.keys(product.images).map((key, index) => (
                                                        <div
                                                            className="center max-w-120 max-h-120 h-100 flex-center border border-gray-100 p-8"
                                                            key={index}
                                                            onClick={() => setMainImage(`${baseUrl}${product.images[key]}`)}
                                                        >
                                                            <img
                                                                className="thum"
                                                                src={`${baseUrl}${product.images[key]}`}
                                                                alt={`Thumbnail ${index}`}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="col-xl-6">
                                    <div className="product-details__content">
                                        <h5 className="mb-12">
                                            {product.productName}
                                        </h5>

                                        <div className="flex-align flex-wrap gap-12">
                                            <div className="flex-align gap-12 flex-wrap">
                                                {/* Ratings and Reviews */}
                                                <div className="flex-align gap-8">
                                                    {[...Array(5)].map((_, index) => (
                                                        <span key={index} className="text-15 fw-medium text-warning-600 d-flex">
                                                            <i className="ph-fill ph-star" />
                                                        </span>
                                                    ))}
                                                </div>
                                                <span className="text-sm fw-medium text-neutral-600">0 Star Rating</span>
                                                <span className="text-sm fw-medium text-gray-500">({product.numReviews} reviews)</span>
                                            </div>
                                            <span className="text-sm fw-medium text-gray-500">|</span>
                                            <span className="text-gray-900">
                                                <span className="text-gray-400">SKU:</span> {product.serialNumber}
                                            </span>
                                        </div>

                                        <span className="mt-24 pt-24 text-gray-700 border-top border-gray-100 d-block"></span>

                                        {/* Product Description and Pricing */}
                                        <div className="my-14 flex-align gap-16 flex-wrap">
                                            <div className="flex-align gap-8">
                                                <h6 className="mb-0">SAR {product.finalPrice} </h6>
                                            </div>
                                            {product.price !== product.finalPrice && (
                                                <>
                                                    <div className="flex-align gap-8">
                                                        <h6
                                                            className="text-xl text-gray-400 mb-0 fw-medium"
                                                            style={{ textDecoration: 'line-through' }}
                                                        >
                                                            SAR {product.price}
                                                        </h6>
                                                    </div>
                                                    <span className="badge bg-success">{product.discountInPercentage}% off</span>
                                                </>
                                            )}
                                        </div>

                                        <p className="text-gray-700">
                                            Model Number: {product.modelNumber}
                                        </p>




                                        <div className="mb-32 row">
                                            <label
                                                htmlFor="stock"
                                                className="text-lg mb-8 text-heading fw-semibold d-block"
                                            >
                                                Quantity
                                            </label>
                                            <span className="text-xl d-flex">
                                                <i className="ph ph-location" />
                                            </span>
                                            <div className="d-flex rounded-4 overflow-hidden col">
                                                {/* Decrement Button */}
                                                <button
                                                    onClick={decrementQuantity}
                                                    type="button"
                                                    className="quantity__minus flex-shrink-0 h-48 w-48 text-neutral-600 bg-gray-50 flex-center hover-bg-main-600 hover-text-white"
                                                >
                                                    <i className="ph ph-minus" />
                                                </button>

                                                {/* Quantity Input (Read-Only) */}
                                                <input
                                                    type="number"
                                                    className="quantity__input flex-grow-1 border border-gray-100 border-start-0 border-end-0 text-center w-32 px-16"
                                                    id="stock"
                                                    value={quantity}
                                                    readOnly
                                                    onChange={(e) => setQuantity(Number(e.target.value))} // Update quantity on change
                                                    min={1}
                                                />

                                                {/* Increment Button */}
                                                <button
                                                    onClick={incrementQuantity}
                                                    type="button"
                                                    className="quantity__plus flex-shrink-0 h-48 w-48 text-neutral-600 bg-gray-50 flex-center hover-bg-main-600 hover-text-white"
                                                >
                                                    <i className="ph ph-plus" />
                                                </button>
                                            </div>

                                            {/* Display the total price */}
                                            <div className="mt-16 col text-end">
                                                <span className="text-xl text-dark fw-medium fw-bold">Total: SAR {totalPrice}</span>
                                            </div>
                                        </div>


                                        <Link
                                            to="#"
                                            className="btn btn-main flex-center gap-8 rounded-8 py-16 fw-normal mt-8"
                                            onClick={addToCartHandler}
                                        >
                                            <i className="ph ph-shopping-cart-simple text-lg" />
                                            Add To Cart
                                        </Link>
                                        <Link
                                            to="#"
                                            className="btn btn-outline-main rounded-8 py-16 fw-normal mt-16 w-100"
                                        >
                                            Buy Now
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}



                    <div className="col-xl-3">
                        <div className="product-details__sidebar py-40 px-32 border border-gray-100 rounded-16">
                           

                            <div className="mb-32">
                                <div className="flex-between flex-wrap gap-8 border-bottom border-gray-100 pb-16 mb-16">
                                    <span className="text-gray-500 fw-bold">Subtotal</span>
                                    <h6 className="text-lg mb-0">SAR {totalPrice}</h6>
                                </div>
                                <div className="flex-between flex-wrap gap-8 border-bottom border-gray-100 pb-16 mb-16">
                                    <span className="text-gray-500">Quantity</span>
                                    <h6 className="text-lg mb-0">{quantity}</h6>
                                </div>
                                <div className="flex-between flex-wrap gap-8 border-bottom border-gray-100 pb-16 mb-16">
                                    <span className="text-gray-500">Shipping</span>
                                    <h6 className="text-lg mb-0">{deliveryCharge}</h6>
                                </div>
                                <div className="flex-between flex-wrap gap-8 mt-2">
                                    <span className="text-gray-500">Expected Delivery</span>
                                    <h6 className="text-lg mb-0 text-center">{expectedDeliveryDate}</h6>
                                </div>

                            </div>

                            <div className="mt-32">
                                <div className="px-16 py-8 bg-main-50 rounded-8 flex-between gap-24 mb-0">
                                    <span className="w-32 h-32 bg-white text-main-600 rounded-circle flex-center text-xl flex-shrink-0">
                                        <i className="ph-fill ph-storefront" />
                                    </span>
                                    <span className="text-sm text-neutral-600">
                                        Sold by:{" "}
                                        <span className="fw-semibold">Hawaseeb  </span>{" "}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-32">
                                <div className="px-32 py-16 rounded-8 border border-gray-100 flex-between gap-8">
                                    <Link to="#" className="d-flex text-main-600 text-28">
                                        <i className="ph ph-heart" />
                                    </Link>
                                    <span className="h-26 border border-gray-100" />
                                    <div className="dropdown on-hover-item">
                                        <button className="d-flex text-main-600 text-28" type="button">
                                            <i className="ph-fill ph-share-network" />
                                        </button>
                                        <div className="on-hover-dropdown common-dropdown border-0 inset-inline-start-auto inset-inline-end-0">
                                            <ul className="flex-align gap-16">
                                                <li>
                                                    <Link
                                                        to="/https://www.facebook.com"
                                                        className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white"
                                                    >
                                                        <i className="ph-fill ph-facebook-logo" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/https://www.twitter.com"
                                                        className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white"
                                                    >
                                                        <i className="ph-fill ph-twitter-logo" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/https://www.linkedin.com"
                                                        className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white"
                                                    >
                                                        <i className="ph-fill ph-instagram-logo" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/https://www.pinterest.com"
                                                        className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white"
                                                    >
                                                        <i className="ph-fill ph-linkedin-logo" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="pt-80">
                    <div className="product-dContent border rounded-24">
                        <div className="product-dContent__header border-bottom border-gray-100 flex-between flex-wrap gap-16">
                            <ul
                                className="nav common-tab nav-pills mb-3"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active"
                                        id="pills-description-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-description"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-description"
                                        aria-selected="true"
                                    >
                                        Description
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="pills-reviews-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-reviews"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-reviews"
                                        aria-selected="false"
                                    >
                                        Reviews
                                    </button>
                                </li>
                            </ul>
                            <Link
                                to="#"
                                className="btn bg-color-one rounded-16 flex-align gap-8 text-main-600 hover-bg-main-600 hover-text-white"
                            >
                                <img src="assets/images/icon/satisfaction-icon.png" alt="" />
                                100% Satisfaction Guaranteed
                            </Link>
                        </div>
                        <div className="product-dContent__box">
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="pills-description"
                                    role="tabpanel"
                                    aria-labelledby="pills-description-tab"
                                    tabIndex={0}
                                >
                                    <div className="mb-40">
                                        <h6 className="mb-24">Product Description</h6>
                                        <p>
                                            {product?.productDescription || "Description not available."}
                                        </p>
                                        <h6 className="my-24">Product Features</h6>
                                        <ul className="list-disc list-inside ms-8 ">
                                            {product?.features?.map((feature) => (
                                                <li key={feature._id} className=" mb-2">
                                                    <strong>{feature.key}:</strong> {feature.value}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="pills-reviews"
                                    role="tabpanel"
                                    aria-labelledby="pills-reviews-tab"
                                    tabIndex={0}
                                >
                                    <div className="row g-4">
                                        <div className="col-lg-6">
                                            <div className="">
                                                <div className="">
                                                    <h6 className="mb-16">Write a Review</h6>
                                                    <span className="text-heading mb-8">
                                                        What is it like to Product?
                                                    </span>
                                                    <div className="flex-align gap-8">
                                                        <span className="text-15 fw-medium text-warning-600 d-flex">
                                                            <i className="ph-fill ph-star" />
                                                        </span>
                                                        <span className="text-15 fw-medium text-warning-600 d-flex">
                                                            <i className="ph-fill ph-star" />
                                                        </span>
                                                        <span className="text-15 fw-medium text-warning-600 d-flex">
                                                            <i className="ph-fill ph-star" />
                                                        </span>
                                                        <span className="text-15 fw-medium text-warning-600 d-flex">
                                                            <i className="ph-fill ph-star" />
                                                        </span>
                                                        <span className="text-15 fw-medium text-warning-600 d-flex">
                                                            <i className="ph-fill ph-star" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <form action="#">
                                                        <div className="">
                                                            <label
                                                                htmlFor="desc"
                                                                className="text-neutral-600 my-8"
                                                            >
                                                                Review
                                                            </label>
                                                            <textarea
                                                                className="common-input rounded-8"
                                                                id="desc"
                                                                defaultValue={
                                                                    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                                                                }
                                                            />
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-main rounded-pill my-10"
                                                        >
                                                            Submit Review
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                            <h6 className="mb-24">Customers Feedback</h6>

                                            <div className="d-flex align-items-start gap-24 pb-44 border-bottom border-gray-100 mb-44">
                                                <img
                                                    src="assets/images/thumbs/comment-img1.png"
                                                    alt=""
                                                    className="w-52 h-52 object-fit-cover rounded-circle flex-shrink-0"
                                                />
                                                <div className="flex-grow-1">
                                                    <div className="flex-between align-items-start gap-8 ">
                                                        <div className="">
                                                            <h6 className="mb-12 text-md">Nicolas cage</h6>
                                                            <div className="flex-align gap-8">
                                                                <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <span className="text-gray-800 text-xs">
                                                            3 Days ago
                                                        </span>
                                                    </div>
                                                    <h6 className="mb-14 text-md mt-24">Greate Product</h6>
                                                    <p className="text-gray-700">
                                                        There are many variations of passages of Lorem Ipsum
                                                        available, but the majority have suffered alteration in
                                                        some form, by injected humour
                                                    </p>
                                                    <div className="flex-align gap-20 mt-44">
                                                        <button className="flex-align gap-12 text-gray-700 hover-text-main-600">
                                                            <i className="ph-bold ph-thumbs-up" />
                                                            Like
                                                        </button>
                                                        <Link
                                                            to="#comment-form"
                                                            className="flex-align gap-12 text-gray-700 hover-text-main-600"
                                                        >
                                                            <i className="ph-bold ph-arrow-bend-up-left" />
                                                            Replay
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="ms-xxl-5">
                                                <div className="d-flex flex-wrap gap-44">
                                                    <div className="border border-gray-100 rounded-8 px-40 py-52 flex-center flex-column flex-shrink-0 text-center">
                                                        <h2 className="mb-6 text-main-600">4.8</h2>
                                                        <div className="flex-center gap-8">
                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                <i className="ph-fill ph-star" />
                                                            </span>
                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                <i className="ph-fill ph-star" />
                                                            </span>
                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                <i className="ph-fill ph-star" />
                                                            </span>
                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                <i className="ph-fill ph-star" />
                                                            </span>
                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                <i className="ph-fill ph-star" />
                                                            </span>
                                                        </div>
                                                        <span className="mt-16 text-gray-500">
                                                            Average Product Rating
                                                        </span>
                                                    </div>
                                                    <div className="border border-gray-100 rounded-8 px-24 py-40 flex-grow-1">
                                                        <div className="flex-align gap-8 mb-20">
                                                            <span className="text-gray-900 flex-shrink-0">5</span>
                                                            <div
                                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={70}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div
                                                                    className="progress-bar bg-main-600 rounded-pill"
                                                                    style={{ width: "70%" }}
                                                                />
                                                            </div>
                                                            <div className="flex-align gap-4">
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                            </div>
                                                            <span className="text-gray-900 flex-shrink-0">
                                                                124
                                                            </span>
                                                        </div>
                                                        <div className="flex-align gap-8 mb-20">
                                                            <span className="text-gray-900 flex-shrink-0">4</span>
                                                            <div
                                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={50}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div
                                                                    className="progress-bar bg-main-600 rounded-pill"
                                                                    style={{ width: "50%" }}
                                                                />
                                                            </div>
                                                            <div className="flex-align gap-4">
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                            </div>
                                                            <span className="text-gray-900 flex-shrink-0">
                                                                52
                                                            </span>
                                                        </div>
                                                        <div className="flex-align gap-8 mb-20">
                                                            <span className="text-gray-900 flex-shrink-0">3</span>
                                                            <div
                                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={35}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div
                                                                    className="progress-bar bg-main-600 rounded-pill"
                                                                    style={{ width: "35%" }}
                                                                />
                                                            </div>
                                                            <div className="flex-align gap-4">
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                            </div>
                                                            <span className="text-gray-900 flex-shrink-0">
                                                                12
                                                            </span>
                                                        </div>
                                                        <div className="flex-align gap-8 mb-20">
                                                            <span className="text-gray-900 flex-shrink-0">2</span>
                                                            <div
                                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={20}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div
                                                                    className="progress-bar bg-main-600 rounded-pill"
                                                                    style={{ width: "20%" }}
                                                                />
                                                            </div>
                                                            <div className="flex-align gap-4">
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                            </div>
                                                            <span className="text-gray-900 flex-shrink-0">5</span>
                                                        </div>
                                                        <div className="flex-align gap-8 mb-0">
                                                            <span className="text-gray-900 flex-shrink-0">1</span>
                                                            <div
                                                                className="progress w-100 bg-gray-100 rounded-pill h-8"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={5}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div
                                                                    className="progress-bar bg-main-600 rounded-pill"
                                                                    style={{ width: "5%" }}
                                                                />
                                                            </div>
                                                            <div className="flex-align gap-4">
                                                                <span className="text-xs fw-medium text-warning-600 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                                <span className="text-xs fw-medium text-gray-400 d-flex">
                                                                    <i className="ph-fill ph-star" />
                                                                </span>
                                                            </div>
                                                            <span className="text-gray-900 flex-shrink-0">2</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ProductDetailsTwo