import { CATEGORY_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const categoryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllCategory: builder.query({
            query: () => ({
                url: CATEGORY_URL
            }),
            providesTags: ['Category'],
            keepUnusedDataFor: 5,
        }),
        getCategory: builder.query({
            query: ({ q = '', page = 1, perPage = 10, sort = 'asc', sortColumn = 'category', discountStatus = null }) => ({
                url: `${CATEGORY_URL}/data`,
                params: {
                    q,
                    page,
                    perPage,
                    sort,
                    sortColumn,
                    discountStatus
                }
            }),
            keepUnusedDataFor: 5,
        }),
        getCategoryById: builder.query({
            query: (id) => ({
                url: `${CATEGORY_URL}/${id}`
            }),
        }),
        addCategory: builder.mutation({
            query: (data) => ({
                url: `${CATEGORY_URL}/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Category'] 
        }),
        editCategory: builder.mutation({
            query: (data) => ({
                url: `${CATEGORY_URL}/${data.id}`,
                method: 'PATCH',
                body: data
            })
        }),
        deleteCategory: builder.mutation({
            query: (data) => ({
                url: `${CATEGORY_URL}/delete/${data.id}`,
                method: 'PATCH',
                body: data
            })
        }),
    })
})

export const {
    useGetAllCategoryQuery,
    useGetCategoryQuery,
    useGetCategoryByIdQuery,
    useAddCategoryMutation,
    useEditCategoryMutation,
    useDeleteCategoryMutation
} = categoryApiSlice