import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetTrendingQuery } from '../slices/bannerApiSlice'

const TrendingOne = () => {

    const [selectedCategory, setSelectedCategory] = useState(null)

    const { data: trending, error, isLoading } = useGetTrendingQuery()

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div></div>
    }

    const backendUrl = process.env.REACT_APP_BACKEND_URL


    return (
        <>
            <section className="trending-products pt-80">
                <div className="container container-lg">
                    {trending.map((trend) => (
                        <>


                            <div key={trend._id} className="border border-gray-100 p-24 rounded-16">
                                <div className="section-heading mb-24">
                                    <div className="flex-between flex-wrap gap-8">
                                        <h5 className="mb-0">{trend.bannerText}</h5>
                                        <ul
                                            className="nav common-tab style-two nav-pills"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            {trend.featuredCategories.map((featured, index) => (
                                                <li
                                                    className="nav-item"
                                                    role="presentation"
                                                    key={index}
                                                    onClick={() => setSelectedCategory(featured.category._id)} // Set selected category
                                                >
                                                    <button
                                                        className={`nav-link ${selectedCategory === featured.category._id ? "active" : ""}`}
                                                        id={`pills-${featured.category._id}-tab`}
                                                        data-bs-toggle="pill"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`pills-${featured.category._id}`}
                                                        aria-selected={selectedCategory === featured.category._id}
                                                    >
                                                        {featured.category.category}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className="trending-products-box rounded-16 overflow-hidden flex-between position-relative mb-24">
                                    <div className="d-md-block d-none ps-xxl-5 ps-md-4">
                                        <img src={`${backendUrl}${trend.bannerLeftImage}`} alt="" />
                                    </div>
                                    <div className="trending-products-box__content px-4 d-block w-100 text-center py-32">
                                        <h6 className="mb-0 trending-products-box__title">
                                            {trend.bannerText}
                                        </h6>
                                        <Link
                                            to="/shop"
                                            className="btn text-heading border-neutral-600 hover-bg-neutral-600 hover-text-white py-10 mt-5 px-18 flex-center d-inline-flex rounded-pill gap-8 fw-medium"
                                            tabIndex={0}
                                        >
                                            Shop Now <i className="ph ph-shopping-cart text-xl d-flex" />
                                        </Link>
                                    </div>
                                    <div className="d-md-block d-none pe-xxl-5 me-xxl-5 pe-md-4">
                                        <img src={`${backendUrl}${trend.bannerRightImage}`} alt="" />
                                    </div>
                                </div>

                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-all"
                                        role="tabpanel"
                                        aria-labelledby="pills-all-tab"
                                        tabIndex={0}
                                    >
                                        <div className="row g-12">
                                            {trend.featuredCategories
                                                .filter((featured) => !selectedCategory || featured.category._id === selectedCategory)
                                                .map((featured) =>
                                                    featured.products.map((product) => (
                                                        <div key={product._id} className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6">
                                                            <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                                                <Link
                                                                    to={`/product-details/${product._id}`}
                                                                    className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                                                >
                                                                    <img
                                                                        src={`${backendUrl}${product.images.image1}`}
                                                                        alt={product.productName}
                                                                        className=" max-w-unset"
                                                                    />
                                                                </Link>
                                                                <div className="product-card__content mt-16">
                                                                    <h6 className="title text-lg fw-semibold my-16">
                                                                        <Link
                                                                            to={`/product-details/${product._id}`}
                                                                            className="link text-line-2"
                                                                            tabIndex={0}
                                                                        >
                                                                            {product.productName}
                                                                        </Link>
                                                                    </h6>
                                                                    <div className="flex-align gap-6">
                                                                        <div className="flex-align gap-8">
                                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                                <i className="ph-fill ph-star" />
                                                                            </span>
                                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                                <i className="ph-fill ph-star" />
                                                                            </span>
                                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                                <i className="ph-fill ph-star" />
                                                                            </span>
                                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                                <i className="ph-fill ph-star" />
                                                                            </span>
                                                                            <span className="text-15 fw-medium text-warning-600 d-flex">
                                                                                <i className="ph-fill ph-star" />
                                                                            </span>
                                                                        </div>
                                                                        <span className="text-xs fw-medium text-gray-500">4.8</span>
                                                                        <span className="text-xs fw-medium text-gray-500">
                                                                            (12K)
                                                                        </span>
                                                                    </div>
                                                                    <span className="py-2 px-8 text-xs rounded-pill text-main-two-600 bg-main-two-50 mt-16">
                                                                        by {product.brand.brand}
                                                                    </span>
                                                                    <div className="product-card__price mt-16 mb-30">

                                                                        <span className="text-heading text-md fw-semibold">
                                                                            AED{" "}{product.finalPrice}
                                                                        </span>
                                                                        <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                                            AED{" "}{product.price}{" "}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="discount py-20">
                                <div className="container container-lg">
                                    <div className="row gy-4">
                                        <div className="col-md-6">
                                            <div className="discount-item rounded-16 overflow-hidden position-relative z-1 h-100 d-flex flex-column align-items-start justify-content-center">
                                                <img
                                                    src="assets/images/bg/discount-bg1.jpg"
                                                    alt=""
                                                    className="position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 z-n1"
                                                />
                                                <div className="w-100 flex-between gap-20">
                                                    <div className="discount-item__content">
                                                        <span className="fw-semibold text-tertiary-600 mb-20">
                                                            {trend.miniBannerProductLeft.discountInPercentage > 0
                                                                ? `UP TO ${trend.miniBannerProductLeft.discountInPercentage}% OFF`
                                                                : "Latest pick"
                                                            }
                                                        </span>
                                                        <h6 className="mb-20">
                                                            {trend.miniBannerProductLeft.productName}
                                                        </h6>
                                                        <Link
                                                            to="/shop"
                                                            className="btn btn-outline-black rounded-pill gap-8"
                                                            tabIndex={0}
                                                        >
                                                            Shop Now
                                                        </Link>
                                                    </div>
                                                    <img
                                                        src={`${backendUrl}${trend.miniBannerProductLeft.images.image1}`}
                                                        alt=""
                                                        className="d-sm-block d-none"
                                                        width="328px" height="146px"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="discount-item rounded-16 overflow-hidden position-relative z-1 h-100 d-flex flex-column align-items-center justify-content-center">
                                                <img
                                                    src="assets/images/bg/discount-bg2.jpg"
                                                    alt=""
                                                    className="position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 z-n1"
                                                />
                                                <div className="w-100 flex-between gap-20">
                                                    <div className="discount-item__content">
                                                        <span className="fw-semibold text-tertiary-600 mb-20">
                                                            {trend.miniBannerProductRight.discountInPercentage > 0
                                                                ? `UP TO ${trend.miniBannerProductRight.discountInPercentage}% OFF`
                                                                : "Trending Now"
                                                            }
                                                        </span>
                                                        <h6 className="mb-20">
                                                            {trend.miniBannerProductRight.productName}
                                                        </h6>
                                                        <Link
                                                            to="/shop"
                                                            className="btn btn-outline-black rounded-pill gap-8"
                                                            tabIndex={0}
                                                        >
                                                            Shop Now
                                                        </Link>
                                                    </div>
                                                    <img
                                                        src={`${backendUrl}${trend.miniBannerProductRight.images.image1}`}
                                                        alt=""
                                                        className="d-sm-block d-none"
                                                        width="328px" height="146px"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </section>

        </>

    )
}

export default TrendingOne