import React, { useState } from "react"
import Preloader from "../helper/Preloader"
import ColorInit from "../helper/ColorInit"
import HeaderTwo from "../components/HeaderTwo"
import Breadcrumb from "../components/Breadcrumb"
import ProductDetailsTwo from "../components/ProductDetailsTwo"
import NewArrivalTwo from "../components/NewArrivalTwo"
import ShippingOne from "../components/ShippingOne"
import NewsletterOne from "../components/NewsletterOne"
import FooterTwo from "../components/FooterTwo"
import BottomFooter from "../components/BottomFooter"
import ScrollToTop from "react-scroll-to-top"
import SubHeader from "../components/SubHeader"
import Sidebar from "../components/Sidebar"
import Profile from '../components/Profile'

const ProfilePage = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)


    return (
        <>
            {/* ColorInit */}
            <ColorInit color={true} />

            {/* ScrollToTop */}
            {/* <ScrollToTop smooth color="#FA6400" /> */}

            {/* Preloader */}
            <Preloader />

            {/* HeaderTwo */}
            <HeaderTwo category={true} />

            {/* SubHeader */}
            <SubHeader onSidebarToggle={toggleSidebar} />

            {/* Sidebar */}
            <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />

            {/* ProductDetailsTwo */}
            <Profile />

            {/* NewArrivalTwo */}
            <NewArrivalTwo />

            {/* ShippingOne */}
            <ShippingOne />

            {/* NewsletterOne */}
            <NewsletterOne />

            {/* FooterTwo */}
            <FooterTwo />

            {/* BottomFooter */}
            <BottomFooter />


        </>
    )
}

export default ProfilePage
