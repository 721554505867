import React, { useState } from "react"

import Preloader from "../../helper/Preloader"
import ColorInit from "../../helper/ColorInit"
import HeaderTwo from "../../components/HeaderTwo"
import Breadcrumb from "../../components/Breadcrumb"
import FooterTwo from "../../components/FooterTwo"
import BottomFooter from "../../components/BottomFooter"
import ShippingOne from "../../components/ShippingOne"
import ScrollToTop from "react-scroll-to-top"
import Register from "../../components/Register"
import SubHeader from "../../components/SubHeader"
import Sidebar from "../../components/Sidebar"
const RegisterPage = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)

  return (
    <>
      {/* ColorInit */}
      <ColorInit color={true} />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#FA6400" />

      {/* Preloader */}
      <Preloader />

      {/* HeaderTwo */}
      <HeaderTwo category={true} />


      {/* SubHeader */}
      <SubHeader onSidebarToggle={toggleSidebar} />

      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />

      {/* Contact */}
      <Register />

      {/* ShippingOne */}
      <ShippingOne />

      {/* FooterTwo */}
      <FooterTwo />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  )
}

export default RegisterPage
