import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useVerifyOtpMutation, useForgotPasswordMutation } from '../slices/userApiSlice'
import { toast } from 'react-toastify'

const VerifyOtp = () => {
    const [otpCode, setOtpCode] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [resendDisabled, setResendDisabled] = useState(false)
    const [timer, setTimer] = useState(60) // Timer for resend OTP

    const { userDetails } = useSelector((state) => state.auth)

    const [verifyOtp] = useVerifyOtpMutation()
    const [resendOtp] = useForgotPasswordMutation()

    const navigate = useNavigate()

    const { search } = useLocation()
    const sp = new URLSearchParams(search)
    const redirect = sp.get('redirect') || '/'

    // Get loginId from localStorage
    const contact = localStorage.getItem('loginId')

    useEffect(() => {
        // If loginId is not present in localStorage, navigate back to /forgot
        if (!contact) {
            navigate('/forgot')
            return
        }

        // Retrieve remaining timer from localStorage if exists
        const savedTimeLeft = localStorage.getItem('otpTimer')
        if (savedTimeLeft && !resendDisabled) {
            const timeLeft = parseInt(savedTimeLeft)
            if (timeLeft > 0) {
                setTimer(timeLeft)
                setResendDisabled(true)
            }
        }

        // Timer for resend OTP button
        let interval = null
        if (resendDisabled && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => {
                    const newTime = prev - 1
                    // Store the remaining time in localStorage
                    localStorage.setItem('otpTimer', newTime)
                    return newTime
                })
            }, 1000)
        } else if (timer === 0) {
            setResendDisabled(false)
            setTimer(60) // Reset timer
            localStorage.removeItem('otpTimer') // Clear stored timer
        }

        return () => clearInterval(interval)
    }, [resendDisabled, timer, contact, navigate])

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match.")
            return
        }

        if (newPassword.length < 8) {
            toast.error('Password must be at least 8 characters long')
            return
        }

        try {
            const res = await verifyOtp({ otpCode, newPassword, contact }).unwrap()

            // Navigate to login page after OTP verification success
            toast.success("OTP verification success")
            navigate('/login', { replace: true })

            localStorage.removeItem('loginId') // Clear stored timer
            localStorage.removeItem('otpTimer', 60)

        } catch (err) {
            toast.error(err?.data?.message || "Something went wrong. Please try again.")
        }
    }

    const handleResendOtp = async () => {
        if (resendDisabled) return

        try {
            const res = await resendOtp({ loginId: contact }).unwrap()

            setResendDisabled(true)
            setTimer(60)
            localStorage.setItem('otpTimer', 60)
            toast.success("OTP Resent")
        } catch (err) {
            console.error("Error during OTP resend:", err)
            toast.error("Failed to resend OTP. Please try again.")
        }
    }

    const isSubmitDisabled = !otpCode || !newPassword || !confirmPassword

    return (
        <div>
            <section className="account pb-40 pt-22">
                <div className="container container-lg">
                    <form onSubmit={handleSubmit}>
                        <div className="row gy-4 d-flex justify-content-center">
                            <div className="col-xl-6">
                                <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                                    <>
                                        <h6 className="text-xl mb-32">Verify OTP and Reset Password</h6>
                                        <div className="mb-24">
                                            <label htmlFor="otpCode" className="text-neutral-900 text-lg mb-8 fw-medium">
                                                Enter OTP<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="common-input"
                                                id="otpCode"
                                                placeholder="OTP"
                                                value={otpCode}
                                                onChange={(e) => setOtpCode(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-24">
                                            <label htmlFor="newPassword" className="text-neutral-900 text-lg mb-8 fw-medium">
                                                New Password<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="password"
                                                className="common-input"
                                                id="newPassword"
                                                placeholder="New Password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-24">
                                            <label htmlFor="confirmPassword" className="text-neutral-900 text-lg mb-8 fw-medium">
                                                Confirm Password<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="password"
                                                className="common-input"
                                                id="confirmPassword"
                                                placeholder="Confirm Password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-15">
                                            <button type="submit" className="btn btn-main py-18 px-40 me-30" disabled={isSubmitDisabled}>
                                                Submit
                                            </button>
                                            <span>
                                                {resendDisabled ? (
                                                    <span>Resend OTP in {timer}s</span>
                                                ) : (
                                                    <button type="btn " className="btn btn-link btn-main text-decoration-none py-18 px-40" onClick={handleResendOtp}>
                                                        Resend OTP
                                                    </button>
                                                )}
                                            </span>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default VerifyOtp
