import { PRODUCTS_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const productsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: ({ q = '',
                page = 1,
                perPage = 10,
                sort = 'asc',
                sortColumn = 'productName',
                inStock = '' }) => ({
                    url: `${PRODUCTS_URL}/data`,
                    params: {
                        q,
                        page,
                        perPage,
                        sort,
                        sortColumn,
                        inStock
                    }
                ,
                credentials: 'include',
                }),
            keepUnusedDataFor: 5,
        }),
        getProductById: builder.query({
            query: (productId) => ({
                url: `${PRODUCTS_URL}/${productId}`,
                credentials: 'include',
            }),
            
            keepUnusedDataFor: 5,
        }),
        addProduct: builder.mutation({
            query: (data) => ({
                url: PRODUCTS_URL,
                method: 'POST',
                body: data,
                credentials: 'include',
            })
        }),
        editProduct: builder.mutation({
            query: (data) => ({
                url: `${PRODUCTS_URL}/edit/${data.productId}`,
                method: 'PATCH',
                body: data,
                credentials: 'include',
            })
        }),
    })
})

export const { useGetProductsQuery, useAddProductMutation, useEditProductMutation, useGetProductByIdQuery } = productsSlice