import { BRAND_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const brandApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllBrand: builder.query({
            query: () => ({
                url: BRAND_URL
            }),
            providesTags: ['Brand'],
            keepUnusedDataFor: 5,
        }),
    })
})

export const {
    useGetAllBrandQuery
} = brandApiSlice