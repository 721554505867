import React, { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { saveShippingAddress } from "../slices/cartSlice"
import { Button, Card, Form } from "react-bootstrap"
import { useAddAddressMutation, useGetAddressQuery } from "../slices/addressApiSlice"
import { useAddAddressToCartMutation } from "../slices/cartApiSlice"
import { Map, View } from "ol"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import OSM from "ol/source/OSM"
import { fromLonLat, toLonLat } from "ol/proj"
import { Feature } from "ol"
import { Point } from "ol/geom"
import { Style, Icon, Text, Fill, Stroke } from "ol/style"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "ol/ol.css"

const ShippingAddress = () => {
    const [selectedAddress, setSelectedAddress] = useState(null)
    const [showAddAddressForm, setShowAddAddressForm] = useState(false)
    const [address, setAddress] = useState("")
    const [state, setState] = useState("")
    const [phone, setPhone] = useState("")
    const [alternatePhone, setAlternatePhone] = useState("")
    const [zipcode, setZipcode] = useState("")

    const { data: addresses, error, isLoading: refetch } = useGetAddressQuery()
    const [addAddress] = useAddAddressMutation()

    const [addAddressToCart] = useAddAddressToCartMutation()

    const mapRef = useRef(null)
    const [map, setMap] = useState(null)
    const vectorSource = useRef(new VectorSource())
    const [currentPinFeature, setCurrentPinFeature] = useState(null)
    const [isSatelliteView, setIsSatelliteView] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const companyLongitude = parseFloat(process.env.REACT_APP_COMPANY_LONGITUDE || "46.6847")
    const companyLatitude = parseFloat(process.env.REACT_APP_COMPANY_LATITUDE || "24.7377")

    // Initialize the map
    useEffect(() => {
        const mapInstance = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM({
                        url: 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png', // OpenStreetMap default English tile source
                    }),
                }),
                new VectorLayer({
                    source: vectorSource.current,
                }),
            ],
            view: new View({
                center: fromLonLat([46.6753, 24.7136]), // Centered on Saudi Arabia
                zoom: 6,
                maxZoom: 18,
                minZoom: 3,
            }),
        })

        setMap(mapInstance)

        mapInstance.on("click", handleMapClick)

        // Add shop marker using the coordinates
        if (companyLongitude && companyLatitude) {
            const shopCoordinates = fromLonLat([companyLongitude, companyLatitude])

            const shopMarker = new Feature({
                geometry: new Point(shopCoordinates),
            })

            shopMarker.setStyle(
                new Style({
                    image: new Icon({
                        src: "http://maps.google.com/mapfiles/ms/icons/green-dot.png", // Your icon
                        scale: 1.5,
                    }),
                    text: new Text({
                        text: 'Our Shop', // Ensure the name is in English
                        font: 'bold 14px sans-serif',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({ color: '#fff', width: 3 }),
                    }),
                })
            )

            vectorSource.current.addFeature(shopMarker)
        }

        return () => {
            if (mapInstance) {
                mapInstance.setTarget(null)
            }
        }
    }, [])

    // Handle address selection
    useEffect(() => {
        if (addresses && addresses.length > 0 && !selectedAddress) {
            setSelectedAddress(addresses[0]._id)
        }
    }, [addresses, selectedAddress])

    const handleSelectAddress = (id) => {
        setSelectedAddress(id)
    }

    const handleAddNewAddress = () => {
        setShowAddAddressForm(!showAddAddressForm)
    }

    const submitHandler = async (e) => {
        e.preventDefault()

        try {
            const addressData = { address, state, phone, alternatePhone, zipcode }
            const response = await addAddress(addressData).unwrap()

            setShowAddAddressForm(false)
        } catch (error) {
            console.error("Failed to add address:", error)
        }
    }

    const handleMapClick = (event) => {
        const clickedCoords = toLonLat(event.coordinate)
        const [longitude, latitude] = clickedCoords

        // If outside Saudi Arabia (min/max bounds), prevent placing pin
        if (latitude < 16.0 || latitude > 32.0 || longitude < 34.0 || longitude > 56.0) {
            toast.error("You cannot place the pin outside of Saudi Arabia.")
            return
        }

        const marker = new Feature({
            geometry: new Point(event.coordinate),
        })

        marker.setStyle(
            new Style({
                image: new Icon({
                    src: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                    scale: 1.5,
                }),
                text: new Text({
                    text: 'Deliver Here', // Ensure the label is in English
                    font: 'bold 14px sans-serif',
                    fill: new Fill({ color: 'red' }),
                    stroke: new Stroke({ color: '#fff', width: 3 }),
                }),
            })
        )

        vectorSource.current.clear()
        vectorSource.current.addFeature(marker)

        setCurrentPinFeature(marker)
    }

    const locateShop = () => {
        const shopCoordinates = fromLonLat([companyLongitude, companyLatitude])

        // Move the map view to the shop location
        map.getView().animate({
            center: shopCoordinates,
            duration: 1000,
        })

        // Clear current custom pin and add the shop marker
        vectorSource.current.clear()
        const shopMarker = new Feature({
            geometry: new Point(shopCoordinates),
        })

        shopMarker.setStyle(
            new Style({
                image: new Icon({
                    src: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                    scale: 1.5,
                }),
                text: new Text({
                    text: 'Our Shop', // Ensure the name is in English
                    font: 'bold 14px sans-serif',
                    fill: new Fill({ color: '#000' }),
                    stroke: new Stroke({ color: '#fff', width: 3 }),
                }),
            })
        )

        vectorSource.current.addFeature(shopMarker)
        setCurrentPinFeature(shopMarker)
    }

    const handleContinue = async () => {
        if (!selectedAddress) {
            toast.error("Please select an address before continuing.")
            return
        }

        // Get the longitude and latitude from the current pin feature (if exists)
        const pinCoordinates = currentPinFeature ? toLonLat(currentPinFeature.getGeometry().getCoordinates()) : null

        if (!pinCoordinates) {
            toast.error("Please place the delivery pin on the map before continuing.")
            return
        }

        const [longitude, latitude] = pinCoordinates

        try {
            // Prepare the payload for adding the address to the cart
            const shippingData = {
                shippingAddressId: selectedAddress,
                longitude: longitude.toString(),
                latitude: latitude.toString(),
            }

            // Call the addAddressToCart API mutation with the prepared data
            await addAddressToCart(shippingData).unwrap()

            // Navigate to the next page
            navigate("/checkout")
        } catch (error) {
            toast.error("Cart is Empty")
            navigate("/shop")
            console.error("Error adding shipping address to cart:", error)
        }
    };


    return (
        <div className="container mx-auto px-4">
            <h5 className="mb-4">Shipping Address</h5>

            <div className="row">
                {/* Map on the left side (col-8) */}
                <div className="col-md-8">
                    <div ref={mapRef} style={{ width: "100%", height: "500px" }}></div>
                    <ToastContainer />
                    <Button
                        onClick={locateShop}
                        className="btn btn-main mt-3"
                    >
                        Locate Us
                    </Button>
                </div>

                {/* Address Form on the right side (col-4) */}
                <div className="col-md-4">
                    <div className="text-end">

                        <Button className="btn-main mb-5" onClick={handleAddNewAddress}>
                            {showAddAddressForm ? 'Back' : 'Add New Address'}
                        </Button>

                    </div>
                    {!showAddAddressForm && (
                        <>
                            <div className="gap-5">
                                {addresses &&
                                    addresses.map((addressItem) => (
                                        <div className="col-md-12 mb-14" key={addressItem._id}>
                                            <Card
                                                className="p-3 rounded shadow-sm"
                                                onClick={() => handleSelectAddress(addressItem._id)}
                                                style={{
                                                    cursor: "pointer",
                                                    border: selectedAddress === addressItem._id ? "2px solid #00cc7b" : "",
                                                }}
                                            >
                                                <p className="mb-0 fw-bold">{addressItem.address}</p>
                                                <p className="mb-0 fw-bold">State : {addressItem.state}</p>
                                                <p className="mb-0 fw-bold">Zip : {addressItem.zipcode}</p>
                                                <p className="mb-0 fw-bold">Ph : {addressItem.phone}</p>
                                                <p className="mb-0 fw-bold">Alt Ph: {addressItem.alternatePhone}</p>
                                            </Card>
                                        </div>
                                    ))}
                            </div>
                            
                        </>
                    )}

                    {showAddAddressForm && (
                        <Form onSubmit={submitHandler}>
                            {/* Address Form Fields */}
                            <Form.Group className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter State"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Alternate Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Alternate Phone"
                                    value={alternatePhone}
                                    onChange={(e) => setAlternatePhone(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Zipcode</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Zipcode"
                                    value={zipcode}
                                    onChange={(e) => setZipcode(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button className="btn-main" type="submit">Save Address</Button>
                        </Form>
                    )}

                    <Button onClick={handleContinue} className="mt-3 btn-main">
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ShippingAddress
