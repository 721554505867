import { ORDER_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const orderSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: () => ({
                url: `${ORDER_URL}/user`
            }),
            providesTags: ['Order'],
            keepUnusedDataFor: 5,
        }),
        getOrderById: builder.query({
            query: (orderId) => `${ORDER_URL}/user/${orderId}`,
        }),
    }),
})

export const { useGetOrdersQuery, useGetOrderByIdQuery } = orderSlice
