import React from "react"
import { Nav } from "react-bootstrap"
import { useSelector } from "react-redux"
import { LinkContainer } from "react-router-bootstrap"

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {

    const { userDetails } = useSelector((state) => state.auth)


    return (
        <Nav className="justify-content-center mb-4">

            {userDetails ? (
                <>

                    <Nav.Item>
                        {step1 ? (
                            <LinkContainer to="/cart">
                                <Nav.Link>Cart</Nav.Link>
                            </LinkContainer>
                        ) : (
                            <Nav.Link disabled>Cart</Nav.Link>
                        )}
                    </Nav.Item>


                    <Nav.Item>
                        {step2 ? (
                            <LinkContainer to="/shipping">
                                <Nav.Link>Shipping</Nav.Link>
                            </LinkContainer>
                        ) : (
                            <Nav.Link disabled>Shipping</Nav.Link>
                        )}
                    </Nav.Item>
                    <Nav.Item>
                        {step3 ? (
                            <LinkContainer to="/checkout">
                                <Nav.Link>Checkout</Nav.Link>
                            </LinkContainer>
                        ) : (
                            <Nav.Link disabled>Checkout</Nav.Link>
                        )}
                    </Nav.Item>
                </>

            ) : (
                <>
                    <Nav.Item>
                        {step1 ? (
                            <LinkContainer to="/login">
                                <Nav.Link>Sign In</Nav.Link>
                            </LinkContainer>
                        ) : (
                            <Nav.Link disabled>Sign In</Nav.Link>
                        )}
                    </Nav.Item>

                </>
            )}
        </Nav>
    )
}

export default CheckoutSteps
