import { CART_URL } from "../constants"
import { apiSlice } from "./apiSlice"

export const cartApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCart: builder.query({
            query: () => ({
                url: CART_URL,
                credentials: 'include',
            }),

            keepUnusedDataFor: 5,
        }),
        addCart: builder.mutation({
            query: (data) => ({
                url: CART_URL,
                method: 'POST',
                body: data,
                credentials: 'include',
            })
        }),
        addAddressToCart: builder.mutation({
            query: (data) => ({
                url: `${CART_URL}/address`,
                method: 'PATCH',
                body: data,
                credentials: 'include',
            })
        }),
        removeFromCart: builder.mutation({
            query: (productId) => ({
                url: `${CART_URL}/remove/${productId}`,
                method: 'DELETE',
                credentials: 'include',
            })
        }),
        updateOrAddCartItem: builder.mutation({
            query: (data) => ({
                url: CART_URL,
                method: 'PUT',
                body: data,
                credentials: 'include',
            })
        }),
    })
})

export const { useGetCartQuery, useAddCartMutation, useAddAddressToCartMutation, useRemoveFromCartMutation, useUpdateOrAddCartItemMutation } = cartApiSlice