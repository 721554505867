import React from 'react'

const Address = () => {
  return (
    <div>
      address
    </div>
  )
}

export default Address
