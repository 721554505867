export const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateCart = (state) => {
  console.log("Cart Items Before Calculation:", state.cartItems)

  // Ensure numbers are parsed correctly
  state.itemsPrice = addDecimals(
    state.cartItems.reduce(
      (acc, item) => acc + Number(item.finalPrice || 0) * Number(item.qty || 1),
      0
    )
  )

  state.totalPrice = Number(state.itemsPrice).toFixed(2)

  console.log("Items Price:", state.itemsPrice)
  console.log("Total Price:", state.totalPrice)

  // Save to localStorage
  localStorage.setItem("cart", JSON.stringify(state))
  return state
};

