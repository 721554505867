import React from "react"
import { useGetOrdersQuery } from "../slices/orderApiSlice"
import { useNavigate } from "react-router-dom"

const Orders = () => {
  const { data: ordersData, error, isLoading } = useGetOrdersQuery()
  const navigate = useNavigate()

  const handleCardClick = (orderId) => {
    navigate(`/order/${orderId}`)
  }

  return (
    <div className="container mt-4">
      <h4 className="mb-4">All Orders</h4>

      {isLoading && <p>Loading orders...</p>}
      {error && <p className="text-danger">Error fetching orders.</p>}

      {ordersData && ordersData.orders.length > 0 ? (
        <div className="row">
          {ordersData.orders.map((order) => (
            <div
              key={order._id}
              className="mb-5"
              style={{ cursor: "pointer" }}
              onClick={() => handleCardClick(order._id)}
            >
              <div className="card shadow-sm h-100">
                <div className="card-body">
                  <h6 className="card-title">Order ID: {order._id}</h6>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Status:{" "}
                    <span
                      className={`badge ${order.status === "Placed"
                          ? "bg-primary"
                          : order.status === "Shipped"
                            ? "bg-warning"
                            : order.status === "Delivered"
                              ? "bg-success"
                              : "bg-secondary"
                        }`}
                    >
                      {order.status}
                    </span>
                  </h6>

                  <p className="card-text">
                    <strong>Product:</strong> {order.product.productName}
                  </p>
                  <p className="card-text">
                    <strong>Expected Delivery:</strong>{" "}
                    {new Date(order.expectedDeliveryDate).toLocaleDateString()}
                  </p>
                </div>
                <div className="card-footer">
                  <small className="text-muted">
                    Ordered on:{" "}
                    {new Date(order.orderPlacedOn).toLocaleDateString()}
                  </small>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  )
}

export default Orders
