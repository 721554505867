import { createSlice } from "@reduxjs/toolkit";
import { updateCart } from "../utils/cartUtils";

const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : { cartItems: [] };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload
      console.log("Item to Add/Update:", item)

      const existItem = state.cartItems.find((x) => x._id === item._id)

      if (existItem) {
        state.cartItems = state.cartItems.map((x) =>
          x._id === existItem._id
            ? { ...x, qty: item.qty } // Set exact quantity passed
            : x
        )
      } else {
        state.cartItems = [
          ...state.cartItems,
          { ...item, qty: item.qty || 1, finalPrice: Number(item.finalPrice) || 0 },
        ]
      }

      return updateCart(state)
    },



    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter((x) => x._id !== action.payload);

      return updateCart(state);
    },

    clearCartItems: (state, action) => {
      state.cartItems = [];
      return updateCart(state);
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  clearCartItems
} = cartSlice.actions;

export default cartSlice.reducer;
